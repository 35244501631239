import React, { createContext, useReducer, useEffect } from "react";
import ilustradozApi from "../api/ilustradozApi";

import { authReducer } from "./AuthReducer";

const authInitialState = {
  status: "checking",
  token: null,
  user: null,
  errorMessage: "",
};
export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, authInitialState);

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    let user = await JSON.parse(localStorage.getItem("ilustradoz-user"));

    //si no hay token no autenticado
    if (!user) return dispatch({ type: "notAuthenticated" });
    try {
      const resp = await ilustradozApi.get("/auth/checktoken");
      dispatch({
        type: "signIn",
        payload: { token: resp.data.token, data: resp.data.data },
      });
    } catch (err) {
      console.log(err);
      return dispatch({ type: "notAuthenticated" });
    }
  };

  const signUp = async ({ email, password, lastName, firstName }) => {
    try {
      const { data } = await ilustradozApi.post("/user/create", {
        email,
        password,
        lastName,
        firstName,
      });
      dispatch({
        type: "signIn",
        payload: {
          token: data.token,
          data: data.data,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: "addError", payload: "Error verifique sus datos" });
    }
  };
  const signIn = async ({ email, password }) => {
    try {
      const { data } = await ilustradozApi.post("/auth/login", {
        email,
        password,
      });
      dispatch({
        type: "signIn",
        payload: {
          token: data.token,
          data: data.data,
        },
      });

      await localStorage.setItem("ilustradoz-user", JSON.stringify(data));
    } catch (error) {
      console.log(error);
      dispatch({ type: "addError", payload: "Error verifique sus datos" });
    }
  };
  const removeError = () => {
    dispatch({ type: "removeError" });
  };
  const logOut = async () => {
    await localStorage.removeItem("ilustradoz-user");
    dispatch({ type: "logout" });
  };

  return (
    <AuthContext.Provider
      value={{ ...state, signUp, signIn, removeError, logOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};
