import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import posthttps from "../../../hook/fetch/posthttps";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function CardCursos(props) {
  let history = useHistory();

  const classes = useStyles();
  const [id, setid] = useState(null);

  useEffect(() => {
    const getLesson = async () => {
      let obj = {
        section: props.text,
      };
      let res = await posthttps("/exam/random", obj);
      setid(res._id);
    };
    getLesson();
  }, []);

  function GoExamen() {
    if (id != null) {
      history.push(`/areas/exam/${id}`);
    }
  }

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.text}
          </Typography>
          <Typography variant="h7" component="h7">
            Cantidad de Preguntas:{props.questionsAmount}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          onClick={() => GoExamen()}
          variant="contained"
          size="small"
          disabled={id == null}
          color="primary"
        >
          EXAMEN
        </Button>
      </CardActions>
    </Card>
  );
}
