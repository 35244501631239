import React, { useEffect, useState } from "react";
import Page from "./components/Page";
import Header from "./components/Header";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardCursos from "./components/CardCursos";
import { Checkbox } from "antd";
import ilustradozApi from "../../../api/ilustradozApi";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const Retos = () => {
  const classes = useStyles();
  const [retos, setretos] = useState([]);
  const [options, setOptions] = useState(["Fácil", "Intermedio", "Difícil"]);
  const [challenges, setChallenges] = useState([]);
  const getRetos = async () => {
    let res = await ilustradozApi.get("/challenge/list/approved");
    if (res.status === 200) {
      setretos(res.data.reverse());
      setChallenges(res.data);
    }
  };
  useEffect(() => {
    getRetos();
  }, []);

  useEffect(() => {
    filterRetos();
  }, [options]);

  const filterRetos = () => {
    let retosfil = challenges.filter((reto) => {
      return options.includes(reto.difficult);
    });
    setretos(retosfil);
  };

  function onChange(checkedValues) {
    setOptions(checkedValues);
  }

  const plainOptions = ["Fácil", "Intermedio", "Difícil"];

  return (
    <>
      <Page className={classes.root}>
        <Header title="CURSOS" subtitle={`Ver Retos`}></Header>

        <div>
          <Card>
            <CardContent>
              <label>Filtrar retos</label>
              <div className="row">
                <div className="col-lg-4">
                  <Checkbox.Group
                    options={plainOptions}
                    defaultValue={options}
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nombre del Reto"
                  />
                </div>
                <div className="col-lg-4">
                  <button class="btn btn-primary" type="button">
                    Buscar
                  </button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {retos != null && (
          <ul className="row">
            {retos.map((e) => (
              <li className="col-6 col-md-3">
                <CardCursos reto={e} />
              </li>
            ))}
          </ul>
        )}
      </Page>
    </>
  );
};

export default Retos;
