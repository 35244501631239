import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
// import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/styles";
import { Drawer, Divider, Paper, Avatar, Typography } from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import { AuthContext } from "../../../../context/AuthContext";
import Navigation from "../../../../components/Navigation";
import navigationConfig from "./navigationConfig";
import navigationAdmin from "./NavigationAdmin";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflowY: "auto",
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
}));

const NavBar = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props;
  const { user } = useContext(AuthContext);
  const classes = useStyles();

  const session = {
    user: {
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQRdwJc1uEbyr0kwsEC4_LNhBrV6NxMPnwY83HFcgi17IY8EjNh",
    },
  };
  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
  }, []);

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {user != null && (
            <div {...rest} className={clsx(classes.root, className)}>
              <div className={classes.content}>
                <div className={classes.profile}>
                  <Avatar
                    alt="Person"
                    className={classes.avatar}
                    component={RouterLink}
                    src={session.user.avatar}
                    to="/settings/general"
                  />
                  <Typography className={classes.name} variant="h4">
                    {`${user.firstName} ${user.lastName}`}
                  </Typography>
                  <Typography variant="body2">{`Carrera: ${
                    user.career ? user.career : "-"
                  }`}</Typography>
                </div>
                <Divider className={classes.divider} />
                <nav className={classes.navigation}>
                  {user.role !== "admin"
                    ? navigationConfig.map((list) => (
                        <Navigation
                          component="div"
                          key={list.title}
                          pages={list.pages}
                          title={list.title}
                        />
                      ))
                    : navigationAdmin.map((list) => (
                        <Navigation
                          component="div"
                          key={list.title}
                          pages={list.pages}
                          title={list.title}
                        />
                      ))}
                </nav>
              </div>
            </div>
          )}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          <div className={classes.content}>
            <div className={classes.profile}>
              <Avatar
                alt="Person"
                className={classes.avatar}
                component={RouterLink}
                src={session.user.avatar}
                to="/settings/general"
              />
              <h4 className={classes.name} variant="h4">
                {`${user.firstName} ${user.lastName}`}
              </h4>
              <Typography variant="body2">{`Carrera: ${
                user.career ? user.career : "-"
              }`}</Typography>
            </div>
            <Divider className={classes.divider} />
            <nav className={classes.navigation}>
              {user.role !== "admin"
                ? navigationConfig.map((list) => (
                    <Navigation
                      component="div"
                      key={list.title}
                      pages={list.pages}
                      title={list.title}
                    />
                  ))
                : navigationAdmin.map((list) => (
                    <Navigation
                      component="div"
                      key={list.title}
                      pages={list.pages}
                      title={list.title}
                    />
                  ))}
            </nav>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
