import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { SketchPicker } from "react-color";
import useForm from "react-hook-form";
import { Select } from "antd";
import clsx from "clsx";
import { Modal } from "antd";
import { makeStyles } from "@material-ui/styles";
import { notification } from "antd";
import ilustradozApi from "../../../api/ilustradozApi";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const { Option } = Select;

const AddCourse = (props) => {
  const classes = useStyles();
  const { update, listSchool } = props;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [school, setSchool] = useState(null);
  const [color, setColor] = useState("#fff");

  const onSubmit = async (event, e) => {
    const obj = {
      name: event.name,
      school: school,
      color: color,
    };
    const res = await ilustradozApi.post("/academic/course", obj);
    if (res.status === 200) {
      notification.success({
        message: "Crear Carrera",
        description: "La carrera fue creada satisfactoriamente.",
        key: `open${Date.now()}`,
      });
      e.target.reset();
      props.setvisible(false);
      update();
      //   history.push("/mychallenges");
    } else {
      notification.error({
        message: "Crear Carrera",
        description: "Ocurrio un error al grabar la carrera",
        key: `open${Date.now()}`,
      });
    }
  };

  const handleChange = (value) => {
    setSchool(value);
  };
  const filterOptions = (inputValue, option) => {
    if (option.props.title != null) {
      const title = option.props.title.toString().toLowerCase();
      return title.startsWith(inputValue.toString().toLowerCase());
    }
  };

  return (
    <Modal
      visible={props.visible}
      footer={null}
      onCancel={() => props.setvisible(false)}
    >
      <>
        <h3>Añadir Curso</h3>
        <form className={clsx(classes.root)} onSubmit={handleSubmit(onSubmit)}>
          <label for="exampleInputEmail1">Nombre del Curso </label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              name="name"
              placeholder="Ingresar Nombre del curso"
              ref={register({
                required: "Nombre Requerido",
              })}
            />
          </div>
          <p className="text-danger"></p>
          <label for="exampleInputEmail1">Color* </label>
          <SketchPicker
            color={color}
            onChangeComplete={(color) => {
              console.log(color.hex);
              setColor(color.hex);
            }}
          />
          <p className="text-danger"></p>
          <label for="exampleInputEmail1">Universidad*</label>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Please select"
            onChange={handleChange}
          >
            {listSchool.map((option) => (
              <Option key={option._id}>{option.shortName}</Option>
            ))}
          </Select>
          {/* <p className="text-danger">
            {errors.shortName && errors.shortName.message}
          </p> */}
          <p>(*) Campos obligatorios</p>
          <Button
            className={classes.submitButton}
            disabled={color === "" || school === null}
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
          >
            Añadir
          </Button>
        </form>
      </>
    </Modal>
  );
};

export default AddCourse;
