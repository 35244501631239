import React, { useState, useEffect } from "react";
import Page from "../../components/Page";
import Header from "./components/Header";
import { useHistory, useParams } from "react-router-dom";
import Question from "./components/Question";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Button, Spin } from "antd";
import moment from "moment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Reloj from "../../assets/img/reloj.png";
import { makeStyles } from "@material-ui/core/styles";
import ilustradozApi from "../../api/ilustradozApi";
import LayoutCore from "../../core/LayoutCore";
import useAnonymous from "../../hook/useAnonymous";
import ModalResults from "./components/ModalResults";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 250,
    alignContent: "center",
  },
  media: {
    height: 100,
    alignContent: "center",
    width: 100,
    padding: "50", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const ExamAnonymous = (props) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const { challenge_id } = useParams();
  const [exam, setexam] = useState([]);
  const [loading, setloading] = useState(true);
  const [caso, setcaso] = useState(0);
  const [ResQuestions, setResQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [time, settime] = useState(500);
  const { anonymousCode } = useAnonymous();
  const history = useHistory();

  useEffect(() => {
    const getExam = async () => {
      try {
        let res = await ilustradozApi.post(
          `/challenge/take_anonymous_exam/${challenge_id}`,
          {
            generate_date: Date.now(),
            anonymous_code: anonymousCode,
          }
        );

        if (res.status === 200) {
          setexam(res.data.exam);
          setQuestions(res.data.questions);
          setFirstChoice(res.data.questions);
          settime(1500);
          let actualMoment = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
          let startMoment = moment(res.data.exam.generate_date).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          let finishMoment = moment(res.data.exam.generate_date)
            .add(res.data.questions.length * 108, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
          console.log(
            "actual",
            actualMoment,
            "fin",
            finishMoment,
            "empezo",
            startMoment
          );
          //no es programado
          if (moment(finishMoment).isAfter(actualMoment)) {
            let tiempoRestante = moment(finishMoment).diff(
              actualMoment,
              "seconds"
            );
            console.log(tiempoRestante);
            settime(tiempoRestante);
            console.log("examen en progreso");
            setcaso(3);
          } else {
            //acabo el examen
            setcaso(1);
          }

          setloading(false);
        } else {
          console.log("object1");
          //ya tomo el examen
          setcaso(4);
          setloading(false);
        }
      } catch (err) {
        console.error(err.response.status);
        if (err.response.status === 400) {
          if (err.response.data.code == "ERR_EXAM_SENT") {
            console.log("object");
            setcaso(4);
            setloading(false);
          }
        }
      }
    };
    getExam();
  }, []);

  useEffect(() => {
    if (time <= 0) {
      window.location.reload();
    }
  }, [time]);

  const renderTime = ({ remainingTime }) => {
    // console.log(remainingTime);
    if (remainingTime === 0) {
      window.location.reload();
      return <div className="timer">Examen terminado</div>;
    }

    return (
      <div className="timer">
        <div className="value">
          <div>
            <div class="d-flex justify-content-center">
              <h1>{remainingTime}</h1>
            </div>
            <br />{" "}
            <div class="d-flex justify-content-center">
              {" "}
              <p>Segundos</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const updateQuestion = (index, rpta) => {
    let arrayquestions = ResQuestions;
    arrayquestions[index].choice = rpta;
    setResQuestions([...arrayquestions]);
  };

  const setFirstChoice = (arrayQuestions) => {
    let newAQuestion = arrayQuestions.map((question, index) => {
      question.choice = question.multipleChoice[0].id;
      return question;
    });
    setResQuestions([...newAQuestion]);
  };

  const EndTest = async () => {
    let obj = {
      delivery_date: Date.now(),
      list_answers: ResQuestions,
    };
    let res = await ilustradozApi.post(
      `/challenge/response-exam/${exam._id}`,
      obj
    );
    setVisible(true);
  };

  function examCase(t) {
    switch (t) {
      case 1:
        return (
          <div class="d-flex text-center justify-content-center">
            <Card className={classes.root}>
              <CardContent justifyContent="center">
                <h3>EXAMEN TERMINADO</h3>

                <img
                  src={Reloj}
                  className="rounded-circle mt-2 mb-4"
                  width="100%"
                  alt=""
                />

                <Button
                  onClick={() => {
                    EndTest();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Ver Resultados
                </Button>
              </CardContent>
            </Card>
          </div>
        );

      case 2:
        return (
          <div class="d-flex text-center justify-content-center">
            <Card className={classes.root}>
              <CardContent justifyContent="center">
                <h3>EXAMEN AUN NO EMPIEZA</h3>

                <img
                  src={Reloj}
                  className="rounded-circle mt-2 mb-4"
                  width="50%"
                  alt=""
                />

                {/* <Button onClick={()=>EndTest()} variant="contained" color="primary">
                Ver Resultados
              </Button> */}
              </CardContent>
            </Card>
          </div>
        );
      case 3:
        return (
          <div className="p-5">
            <h2>{exam.title}</h2>
            <div class="row">
              <div class="col"></div>{" "}
              <div class="col">
                <div
                  style={{
                    display: "table",
                    margin: "0 auto",
                  }}
                >
                  <CountdownCircleTimer
                    isPlaying
                    duration={time}
                    colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                  >
                    {renderTime}
                  </CountdownCircleTimer>
                </div>
              </div>{" "}
              <div class="col"></div>
            </div>
            <br />
            {questions.map((e, index) => {
              return (
                <Question
                  index={index}
                  key={index}
                  updateQuestion={updateQuestion}
                  data={e}
                />
              );
            })}

            <div className="d-flex bd-highlight mb-3">
              {/* <div className="mr-auto p-2 bd-highlight">
            {" "}
            <Button type="primary">Responder Luego</Button>
          </div> */}

              <div className="p-2 bd-highlight">
                <Button onClick={() => EndTest()} type="primary">
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        );

      case 4:
        return (
          <div class="d-flex text-center justify-content-center">
            <Card className={classes.root}>
              <CardContent justifyContent="center">
                <h3>YA TOMO EL EXAMEN</h3>

                <img
                  src={Reloj}
                  className="rounded-circle mt-2 mb-4"
                  width="50%"
                  alt=""
                />

                <Button
                  onClick={() =>
                    history.push(`/areas/exam/${challenge_id}/results`)
                  }
                  variant="contained"
                  color="primary"
                >
                  Ver Resultados
                </Button>
              </CardContent>
            </Card>
          </div>
        );
      default:
        return (
          <div class="d-flex text-center justify-content-center">
            <Card className={classes.root}>
              <CardContent justifyContent="center">
                <h3>YA TOMO EL EXAMEN</h3>

                <img
                  src={Reloj}
                  className="rounded-circle mt-2 mb-4"
                  width="50%"
                  alt=""
                />

                {/* <Button onClick={()=>EndTest()} variant="contained" color="primary">
            Ver Resultados
          </Button> */}
              </CardContent>
            </Card>
          </div>
        );
    }
  }

  return (
    <LayoutCore>
      {visible && (
        <ModalResults
          visible={visible}
          setVisible={setVisible}
          anonymousCode={anonymousCode}
          id={challenge_id}
        />
      )}
      <div className="container pt-3">
        {loading === true ? <Spin /> : examCase(caso)}
      </div>
    </LayoutCore>
  );
};

export default ExamAnonymous;
