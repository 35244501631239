import React, { useEffect, useState } from "react";
import Layout from "../../core/LayoutCore";
import { Card, CardContent } from "@material-ui/core";
import ilustradozApi from "../../api/ilustradozApi";
import { Helmet } from "react-helmet";
const BlogPost = ({ match }) => {
  const [data, setdata] = useState({});
  useEffect(() => {
    const getPost = async () => {
      let res = await ilustradozApi.get(`/blog/post/${match.params.id}`);
      if (res.status === 200) {
        setdata(res.data);
      }
    };
    getPost();
  }, []);

  return (
    <Layout>
      {data.title && (
        <Helmet>
          <title>{data.title.toUpperCase()}</title>
          <meta name="description" content={data.brief.toString().replace('&nbsp;',' ') } />
        </Helmet>
      )}
      <div className="container pt-5">
        <Card>
          <CardContent>
            {data.title != null && <h1>{data.title.toUpperCase()}</h1>}
            <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
          </CardContent>{" "}
        </Card>
      </div>
    </Layout>
  );
};

export default BlogPost;
