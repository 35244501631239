import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Button } from "antd";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function CardCursos(props) {
  let history = useHistory();
  const [visible, setvisible] = useState();
  const classes = useStyles();

  const goExamen = (id) => {
    if (id != null) {
      history.push(`/areas/exam/${id}`);
    }
  };
  return (
    <Card>
     

      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {props.reto.name}
        </Typography>
        <p>
          {props.reto.course.name}-{props.reto.course.university}
        </p>
        <p>Dificultad: {props.reto.difficult}</p>
        <Button
          disabled={props.reto._id == null}
          onClick={() => goExamen(props.reto._id)}
          type="primary"
        >
          Tomar examen
        </Button>
      </CardContent>
    </Card>
  );
}
