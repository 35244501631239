import { Card, CardContent } from "@material-ui/core";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import React, { useState } from "react";
import { Modal } from "antd";

const Block = ({ data }) => {
  const [isVisible, setVisible] = useState(false);

  const blockRender = () => {
    let newData = [];
    newData.push([data[0]]);
    if ((data.length - 1) % 2 === 0) {
      for (let i = 1; i < data.length; i += 2) {
        newData.push([data[i], data[i + 1]]);
      }
    } else {
      if (data.length === 2) {
        newData[0].push(data[1]);
      } else {
        //solo el primer elemento pushear 3 luego 2
        for (let i = 1; i < data.length - 1; i += 2) {
          if (i === 1) {
            newData.push([data[i], data[i + 1], data[i + 2]]);
          } else {
            newData.push([data[i + 1], data[i + 2]]);
          }
        }
      }
    }
    return newData.map((item, index) => {
      return (
        <div className={`row justify-content-center`}>
          {item.map((itemChild) => {
            return (
              <>
                <div
                  onClick={() => setVisible(true)}
                  style={{ width: "180px", margin: "10px" }}
                >
                  <CircularProgressbarWithChildren
                    value={itemChild.percentage}
                    styles={buildStyles({
                      pathColor:
                        itemChild.percentage == 100 ? "#cba04b" : "#4c4c4c",
                    })}
                  >
                    <img
                      style={{
                        width: "50%",
                        marginTop: -5,
                        filter:
                          Number(itemChild.percentage) === 100
                            ? "grayscale(0%)"
                            : "grayscale(100%)",
                      }}
                      src="https://i.imgur.com/b9NyUGm.png"
                      alt="doge"
                    />

                    <div
                      style={{
                        fontSize: 12,
                        marginTop: -5,
                        textAlign: "center",
                      }}
                    >
                      <strong>{itemChild.percentage + "%"}</strong>{" "}
                      {itemChild.lesson.name}
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              </>
            );
          })}
        </div>
      );
    });
  };

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>{data.length > 0 && blockRender()}</CardContent>
      </Card>
      {isVisible && (
        <Modal
          title={"itemChild.title"}
          visible={isVisible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <Card>
            <CardContent>
              <p>{"itemChild.description"}</p>
            </CardContent>
          </Card>
        </Modal>
      )}
    </>
  );
};

export default Block;
