import React, { useState, useEffect } from "react";


import { Card, CardContent } from "@material-ui/core";

import Layout from "../../core/LayoutCore";
import CardCursos from "./components/CardCursos";
import { Spin } from "antd";
import ilustradozApi from "../../api/ilustradozApi";
const CursosPublicos = () => {
  const [loading, setloading] = useState(true);

  const [data, setdata] = useState([]);

  async function getRetos() {
    let res = await ilustradozApi.get("/challenge/list/approved");
    if (res.status === 200) {
      setdata(res.data);
    }
    setloading(false);
  }

  useEffect(() => {
    getRetos();
  }, []);

  return (
    <Layout>
      <div className="container pt-3">
        <Card>
          <CardContent>
            <h1 className="text-center  ">RETOS ILUSTRADOZ</h1>
          </CardContent>
        </Card>

        <div className="row">
          <div className="col-lg-4">
            <div className="input-group m-2">
              <input label="search" type="text" className="form-control" />
              <div className="input-group-append">
                <span
                  className="input-group-text progress-bar"
                  id="basic-addon2"
                >
                  Buscar
                </span>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="text-center">
            <Spin tip="Cargando..."></Spin>
          </div>
        ) : (
          <div>
            {data != null && (
              <ul className="row">
                {data.map((e) => (
                  <li className="col-6 col-md-3">
                    <CardCursos reto={e} publicStatus />
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CursosPublicos;
