import Axios from "axios";

//const baseURL = "http://localhost:8080/api";
const baseURL = "https://ilustradoz.online/api";

const ilustradozApi = Axios.create({ baseURL });

ilustradozApi.interceptors.request.use(async (config) => {
  let user = await JSON.parse(localStorage.getItem("ilustradoz-user"));
  if (user) {
    config.headers["Authorization"] = user.token;
  }
  return config;
});

export default ilustradozApi;
