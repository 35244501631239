import React, { useEffect, useState } from "react";
import { Table } from "antd";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Page from "../../../components/Page/Page";
import Header from "./components/Header";
import ReactStars from "react-stars";
import { makeStyles } from "@material-ui/styles";

import deletehttps from "../../../hook/fetch/deletehttps";

import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../../config/api";
import UserServices from "../../../services/UserServices";
import ilustradozApi from "../../../api/ilustradozApi";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));

const Retos = (props) => {
  const classes = useStyles();
  const [update, setupdate] = useState(null);

  const [retos, setRetos] = useState([]);

  useEffect(() => {
    getMyChallenges();
  }, [update]);

  const getMyChallenges = async () => {
    let res = await ilustradozApi.get(`/challenge/my-challenge`);

    if (res.status === 200) {
      setRetos(res.data);
    }
  };

  const sentReviewChallenge = (id) => {
    let res = ilustradozApi.post(`/challenge/sent-challenge/${id}`);
    getMyChallenges();
  };

  const columns = [
    {
      title: "Nº",
      key: "id",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Institución",
      dataIndex: "institution",
      key: "institution",
    },

    {
      title: "Curso",
      dataIndex: "course",
      key: "course",
      render: (text, record, index) => <> {text.name} </>,
    },
    {
      title: "Tema",
      dataIndex: "topic",
      key: "topic",
    },
    {
      title: "Dificultad",
      dataIndex: "difficult",
      key: "difficult",
    },

    {
      title: "Puntuaciones",
      dataIndex: "rate",
      width: "10%",
      render: (text, record, index) => (
        <ReactStars
          count={5}
          value={text}
          edit={false}
          // onChange={ratingChanged}
          size={24}
          color2={"#ffd700"}
        />
      ),
    },

    {
      title: "Acciones",
      key: "action",
      dataIndex: "_id",
      width: "25%",
      render: (text, record, index) => (
        <span>
          {/* <button
            type="button"
            onClick={() => onClickLink(text)}
            className="mr-1 btn btn-success"
          >
            Ver Resultados
          </button>
          <button type="button" className="mr-1 btn btn-success">
            Editar
          </button> */}
          <Link to={{ pathname: `/mychallenges/${text}/addquestions` }}>
            <button type="button" className=" mr-1 btn btn-warning">
              Agregar Preguntas
            </button>
          </Link>
          <button
            type="button"
            onClick={() => deleteChallenge(text)}
            className="btn btn-danger"
          >
            Eliminar
          </button>
          {record.status === ("created" || "disapproved") && (
            <button
              type="button"
              onClick={() => sentReviewChallenge(record._id)}
              className="ml-1 btn btn-primary"
            >
              Enviar a Revisar
            </button>
          )}
        </span>
      ),
    },
  ];

  async function deleteChallenge(id) {
    let res = await deletehttps(`removechallenge/${id}/{user.getId()}`);
    setupdate({ ...true });
  }
  const buildInit = () => {
    return {
      method: "GET",
      headers: UserServices.createHeaders(),
    };
  };
  const onClickLink = (id) => {
    fetch(`${API_BASE_URL}/getreport/$.getId()}/${id}`, buildInit()).then(
      (response) => {
        if (response.ok) {
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = `Resultados.xlsx`;
            a.click();
          });
        }
      }
    );
  };

  return (
    <Page className={classes.root}>
      <Header
        title="crear contenido"
        subtitle="MIS RETOS"
        setupdate={setupdate}
      />
      <div className="mt-3">
        <Card>
          <CardContent>
            <Table bordered={true} columns={columns} dataSource={retos} />
          </CardContent>{" "}
        </Card>
      </div>
    </Page>
  );
};

export default Retos;
