import React, { Suspense, useState } from "react";
import SessionStorageService from "../../auth/SessionStorageService.js";
import SessionTimeService from "../../auth/SessionTimeService.js";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import { Modal, Button } from "antd";
import { NavBar, TopBar, ChatBar } from "./components";
import anio from "../../config/anio";
const Home = (props) => {
  const [año, setAño] = useState(anio.getActual());
  const [modal, setmodal] = useState(anio.validaranio());
  console.log(props);
  const useStyles = makeStyles(() => ({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    topBar: {
      zIndex: 2,
      position: "relative",
    },
    container: {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
    },
    navBar: {
      zIndex: 3,
      width: 256,
      minWidth: 256,
      flex: "0 0 auto",
    },
    content: {
      overflowY: "auto",
      flex: "1 1 auto",
    },
  }));

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const enviar = () => {
    SessionStorageService.set("anio-actual", {
      expiresAt: SessionTimeService.estimatedTime(),
      value: año,
    });
    setmodal(false);
  };

  return (
    <>
      <div className={classes.root}>
        <TopBar
          history={props.history}
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
        <div className={classes.container}>
          <NavBar
            className={classes.navBar}
            onMobileClose={handleNavBarMobileClose}
            openMobile={openNavBarMobile}
          />
          <main
            className={classes.content}
            style={{ backgroundColor: "#f4f7fe" }}
          >
            <Suspense fallback={<LinearProgress />}>{props.children}</Suspense>
          </main>
        </div>
        {/* <ChatBar /> */}
      </div>
      <Modal title="RECUERDA" visible={modal} footer={[]} closable={false}>
        <div className="text-center">
          <div>
            <h2>
              Si eres nuevo no olvides llenar tus datos en la sección perfil
              para poder realizar cuestionarios y ver tus clases
            </h2>
          </div>

          <div>
            <button onClick={enviar} className="btn btn-primary">
              Aceptar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Home;
