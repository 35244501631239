import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Card from "@material-ui/core/Card";
import Page from "../../components/Page/Page";
import Header from "./components/Header";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/styles";
import ilustradozApi from "../../api/ilustradozApi";
import AddTopic from "./components/AddTopic";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const Topics = () => {
  const classes = useStyles();
  const [data, setdata] = useState([]);
  const [courses, setCourses] = useState([]);
  const [visible, setvisible] = useState(false);
  const [shortName, setshortName] = useState();
  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Curso",
      dataIndex: "university",
      key: "university",
      render: (value, record) => <p>{record.course.name}</p>,
    },
    {
      title: "Numero",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Acciones",
      dataIndex: "shortName",
      key: "shortName",
      render: (value, record) => (
        <></>
        // <button
        //   type="button"
        //   onClick={() => addCareer(value)}
        //   className="btn btn-primary"
        // >
        //   Agregar Carrera
        // </button>
      ),
    },
  ];

  useEffect(() => {
    getSubjects();
  }, []);

  const getSubjects = async () => {
    let res = await ilustradozApi.get("/academic/topics");
    if (res.status === 200) {
      setdata(res.data);
    }
    const response = await ilustradozApi.get("/academic/courses");
    if (response.status === 200) {
      setCourses(response.data);
    }
  };
  return (
    <>
      <Page className={classes.root}>
        <Header
          update={getSubjects}
          title="Panel Admin"
          buttonText="Agregar Tema"
          onActionBtn={() => setvisible(true)}
        />
        <AddTopic
          courses={courses}
          shortName={shortName}
          update={getSubjects}
          setvisible={setvisible}
          visible={visible}
        />
        <div className="mt-3">
          <Card>
            <CardContent>
              <Table bordered={true} columns={columns} dataSource={data} />
            </CardContent>{" "}
          </Card>
        </div>
      </Page>
    </>
  );
};

export default Topics;
