import React from "react";
import Header from "./components/Header";
import Page from "./components/Page";
import { makeStyles } from "@material-ui/styles";
import { Tabs, Tab, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import General from "./components/General";

import Security from "./components/Security";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const Profile = props => {
  const { match } = props;
  const classes = useStyles();
  let history = useHistory();
  const { tab } = match.params;
  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  const tabs = [
    { value: "general", label: "GENERAL" },
    // { value: "security", label: "SEGURIDAD" },
  ];

  return (
    <>
      <Page className={classes.root}>
        <Header title="PERFIL" subtitle={`Ver Mi Perfil`}></Header>
        <Tabs
          className={classes.tabs}
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={tab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {tab === "general" && <General />}
          {tab === "security" && <Security />}
        </div>
      </Page>
    </>
  );
};

export default Profile;
