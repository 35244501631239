import React, { useEffect, useState } from "react";
import { Button, Table, Tag } from "antd";
import { Button as ButtonMUI } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Page from "../../components/Page/Page";
import { makeStyles } from "@material-ui/styles";
import Drawer from "@material-ui/core/Drawer";
import Header from "../../components/Global/Header";
import ilustradozApi from "../../api/ilustradozApi";
import AddBlock from "./AddBlock";
import clsx from "clsx";
import useForm from "react-hook-form";
import CKEditorAws from "../../components/CKEditorAws";
import SelectQuestions from "./SelectQuestions";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));

const Blocks = (props) => {
  const classes = useStyles();
  const [update, setupdate] = useState(null);
  const [visible, setVisible] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [data, setdata] = useState("");
  const { register, errors, handleSubmit } = useForm({});

  useEffect(() => {
    getMyChallenges();
  }, [update]);

  const getMyChallenges = async () => {
    let res = await ilustradozApi.get(`/lessons/all-lessons`);

    if (res.status === 200) {
      setLessons(res.data);
    }
  };

  const publishLesson = async (_id) => {
    let res = await ilustradozApi.post(`/lessons/publish`, { _id });
    if (res.status === 200) {
      setupdate({ ...true });
    }
  };

  const editDrawer = () => {
    setDrawer(true);
  };

  const columns = [
    {
      title: "Nº",
      key: "id",
      width: "3%",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "Estado",
      dataIndex: "published",
      render: (status) => (
        <div>
          {status ? (
            <Tag color="#87d068">Publicado</Tag>
          ) : (
            <Tag color="#f50">Sin Publicar</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "name",
      width: "25%",
      key: "name",
    },

    {
      title: "Institución",
      dataIndex: "subject.university",
      key: "institution",
    },

    {
      title: "Curso",
      dataIndex: "subject.name",
      key: "course",
    },

    {
      title: "Bloque",
      dataIndex: "block",
      key: "subject",
      width: "5%",
    },

    {
      title: "Acciones",
      key: "action",
      dataIndex: "_id",
      width: "25%",
      render: (id) => (
        <>
          <Button onClick={() => publishLesson(id)} type="primary">
            Publicar Tarea
          </Button>
          <Button onClick={() => editDrawer()} type="secondary">
            Editar Tarea
          </Button>
        </>
      ),
    },
  ];

  const onSubmit = async (event, e) => {};

  return (
    <Page className={classes.root}>
      <Header
        title="crear contenido"
        subtitle="MIS LECCIONES APRENDO"
        titleText="Crear Lección"
        buttonAction={() => setVisible(true)}
        add
      />
      <Drawer anchor={"right"} open={drawer} onClose={() => setDrawer(!drawer)}>
        <div style={{ padding: 30 }}>
          <h3>Editar Lección</h3>
          <form
            autoComplete="off"
            className={clsx(classes.root)}
            onSubmit={handleSubmit(onSubmit)}
          >
            <label>Teoría </label>
            <CKEditorAws data={data} setData={(data) => setdata(data)} />
            <label>Grupo 1 </label>
            <SelectQuestions options={[]} />
            <label>Grupo 2 </label>
            <SelectQuestions options={[]} />
            <label>Grupo 3 </label>
            <SelectQuestions options={[]} />
            <label>Grupo 4 </label>
            <SelectQuestions options={[]} />
            <br />
            <br />
            <ButtonMUI
              className={classes.submitButton}
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
            >
              Guardar
            </ButtonMUI>
          </form>
        </div>
      </Drawer>
      <AddBlock
        visible={visible}
        setVisible={setVisible}
        setupdate={setupdate}
      />
      <div className="mt-3">
        <Card>
          <CardContent>
            <Table bordered={true} columns={columns} dataSource={lessons} />
          </CardContent>
        </Card>
      </div>
    </Page>
  );
};

export default Blocks;
