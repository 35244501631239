import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import Header from "./components/Header";
import CardAreas from "./components/CardAreas.jsx";
import { makeStyles } from "@material-ui/styles";

import posthttps from "../../hook/fetch/posthttps";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const Areas = () => {
  const classes = useStyles();
  const [areas, setareas] = useState([]);
  const [loading, setloading] = useState(true);
  async function getareas() {
    let obj = {
      // userId : user.getId()
    };

    let res = await posthttps("sections/list", obj);
    console.log(res);
    setareas(res);
    setloading(false);
  }
  useEffect(() => {
    getareas();
  }, []);

  return (
    <>
      <Page>
        <Header title="AREAS" subtitle={`Ver Areas`} />
        {loading == true ? (
          <CircularProgress />
        ) : (
          <div className="p-4">
            {areas.length > 0 && (
              <ul className="row">
                {areas.map((e) => (
                  <li className="col-6 col-md-3" key={e.id}>
                    <CardAreas
                      questionsAmount={e.questionsAmount}
                      text={e.name}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </Page>
    </>
  );
};

export default Areas;
