import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import AddRetos from "../AddRetos";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Header = (props) => {
  const { className, setupdate, ...rest } = props;
  const [visible, setvisible] = useState(false);
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <AddRetos
        setvisible={setvisible}
        setupdate={setupdate}
        visible={visible}
      />
      <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {props.title}
          </Typography>
          <Typography component="h1" variant="h3">
            {props.subtitle}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            className="mr-1"
            color="primary"
            onClick={() => setvisible(true)}
            variant="contained"
          >
            Crear Reto
          </Button>
          {/* <Button
            color="primary"
            onClick={() => setvisible(true)}
            variant="contained"
          >
            Carga Masiva de productos
          </Button> */}
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
