import React from 'react'
import Guia1 from "../../../assets/img/guia-1.png"
import Guia3 from "../../../assets/img/guia-3.svg"
import Guia2 from "../../../assets/img/guia-2.png"
import Guia4 from "../../../assets/img/guia-4.png"
const Card  =(props)=>{

  return(
    <div className="text-center col-lg-3">
      <img src={props.img}  style={{ height: "150px" }} alt=""/>
  <h5>{props.text}</h5>
      

    </div>
  )
}

const Guia = () => {
  return (
    <div style={{ backgroundColor: "white", }} className="pt-5 pb-5 " >
      <div className="container">
        <div className="text-center mb-5">
          <h3>APRENDE Y CRECE CON ILUSTRADOZ</h3>
        </div>
      <div className="row">
      <Card img={Guia1} text="Crea exámenes únicos para la comunidad"/>
        <Card img={Guia2}  text="Desarrolla exámenes tipo examen de admisión completamente gratis"/>
        <Card img={Guia3} text="Lee nuestros resúmenes de los principales temas de admisión"/>
        <Card  img={Guia4} text="Mide tu progreso en el ranking y gráficas"/>

      </div>
      </div>
    </div>
  )
}

export default Guia
