import React from "react";
import { Table } from "antd";
const TableQuestions = (props) => {
  const columns = [
    {
      title: "Contenido",
      dataIndex: "contentQuestion",
      key: "correctQuestion",
    },

    // {
    //   title: 'Solución',
    //   dataIndex: 'correctAnswer',
    //   key: 'correctAnswer',
    //   render: (text)=>(
    //     <span>{Number(text)+1}</span>
    //   )
    // },
    {
      title: "Acciones",
      dataIndex: "_id",
      key: "_id",
      render: (text, record, index) => (
        <button className="btn btn-danger" onClick={() => props.update(text)}>
          Eliminar
        </button>
      ),
    },
  ];
  return (
    <>
      <Table dataSource={props.questions} columns={columns} />;
    </>
  );
};

export default TableQuestions;
