import React from "react";

const Tyc = () => {
  return (
    <div className="container mt-5">
      <h1>Términos y Condiciones de Uso</h1>
      <p>Actualizado 2022-06-22</p>
      <p>
        Al acceder al app de Ilustradoz usted confirma que está de acuerdo y sujeto a
        los términos de servicio contenidos en los Teerminos y condiciones que
        se descripon a continuación Estos términos se aplican a todo el sitio
        web, app y a cualquier correo electrónico u otro tipo de comunicación
        entre usted y Ilustradoz Bajo ninguna circunstancia el equipo de Ilustradoz será
        responsable de ningún daño directo, indirecto especial,usted asume los
        costos de los mismos Ilustradoz no será responsable de ningun resultado que
        pueda ocurrir durante el uso de uno de nuestros recursos Nos reservamos
        el derecho de cambiar y revisar la polica de uso en cualquier momento
      </p>
      <p>
        Modificaciones a nuestra plataforma Ilustradoz se reserva el derecho de
        modificar, suspender o interrumpir, temporal o permanentemente, la
        plataforma o cualquier servicio al que se conecte, con o sin previo
        aviso y sin responsabilidad ante usted. Actualizaciones a nuestra
        plataforma Ilustradoz puede, de vez en cuando, proporcionar mejoras a las
        características / funcionalidad de la plataforma, que pueden incluir
        parches, corrección de errores, actualizaciones, mejoras y otras
        modificaciones ("Actualizaciones") Las actualizaciones pueden modificar
        o eliminar ciertas características y/o funcionalidades de la plataforma
        Usted acepta que Ilustradoz no tiene la obligación de (1) proporcionar
        Actualizaciones, o (1) continuar proporcionándole o habilitando
        caracteristicas y/o funcionalidades particulares de la plataforma
        Además, acepta que todas las Actualizaciones (1) se considerarán una
        parte integral de la plataforma y (ii) estarán sujetas a los términos y
        condiciones de este Acuerdo.
      </p>
      <p>
        Ilustradoz, garantiza que la información personal que usted envía cuenta con
        la seguridad necesaria. Los datos ingresados por usuario o en el caso de
        requerir una validación no serán entregados a terceros.
      </p>
      <p>
        La suscripción a boletines de correos electrónicos publicitarios es
        voluntaria
      </p>
    </div>
  );
};

export default Tyc;
