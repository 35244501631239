import React from "react";
import Layout from "../../core/LayoutCore";
import Home from "./components/Home";
// import Equipo from "./components/Equipo"
import NuestroEquipo from "./components/NuestroEquipo";
import Mision from "./components/Mision"
import Guia from "./components/Guia"
import Posts from "./components/Posts"
import Contactanos from "./components/Contactanos";
const Landing = () => {
  return (
    <Layout>
      <Home />
      <Mision/>
      <Guia/>
      <Posts/>
      <NuestroEquipo />

      <Contactanos />
    </Layout>
  );
};

export default Landing;
