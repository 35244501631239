import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import ReactStars from "react-stars";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {},
  dates: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  startDateButton: {
    marginRight: theme.spacing(1),
  },
  endDateButton: {
    marginLeft: theme.spacing(1),
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Header = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  function getGreetingTime() {
    var today = new Date();
    var g = null; //return g
    var split_afternoon = 12; //24hr time to split the afternoon
    var split_evening = 17; //24hr time to split the evening
    var currentHour = moment(today).format("HH");

    if (currentHour >= split_afternoon && currentHour <= split_evening) {
      g = "Buenas tardes";
    } else if (currentHour >= split_evening) {
      g = "Buenas noches";
    } else {
      g = "Buenos días";
    }

    return g;
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <p>{props.title}</p>
      <h3 className='card-outer-title text-uppercase'>
        {`${getGreetingTime()}   ${props.subtitle}`}
      </h3>
      <ReactStars
        count={5}
        value={3}
        edit={false}
        // onChange={ratingChanged}
        size={24}
        color2={"#ffd700"}
      />
      {/* <Typography variant="subtitle1">Here's what's happening</Typography> */}
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {};

export default Header;
