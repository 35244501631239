import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";

import useForm from "react-hook-form";
import { Select } from "antd";
import clsx from "clsx";
import { Modal } from "antd";
import { makeStyles } from "@material-ui/styles";
import { notification } from "antd";
import ilustradozApi from "../../../api/ilustradozApi";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));

const { Option } = Select;

const AddTopic = (props) => {
  const classes = useStyles();
  const [course, setCourse] = useState(null);
  const { update, shortName, courses } = props;
  const { register, errors, handleSubmit, watch, setValue } = useForm({});

  const handleChange = (value) => {
    setCourse(value);
  };

  console.log(courses);

  const onSubmit = async (event, e) => {
    const obj = {
      name: event.name,
      number: event.number,
      course: course
    };
    const res = await ilustradozApi.post(`/academic/topic`, obj);
    if (res.status === 200) {
      notification.success({
        message: "Crear Tema",
        description: "La carrera fue creada satisfactoriamente.",
        key: `open${Date.now()}`,
      });
      e.target.reset();
      props.setvisible(false);
      update();
      //   history.push("/mychallenges");
    } else {
      notification.error({
        message: "Crear Tema",
        description: "Ocurrio un error al grabar la carrera",
        key: `open${Date.now()}`,
      });
    }
  };

  return (
    <Modal
      visible={props.visible}
      footer={null}
      onCancel={() => props.setvisible(false)}
    >
      <>
        <h3>Añadir Tema</h3>
        <form className={clsx(classes.root)} onSubmit={handleSubmit(onSubmit)}>
          {" "}
          <label for="exampleInputEmail1">Nombre del Tema* </label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              name="name"
              placeholder="Ingresar Nombre de la carrera"
              ref={register({
                required: "Nombre Requerido",
              })}
            />
          </div>
          <p className="text-danger">
            {errors.career && errors.career.message}
          </p>
          <label for="exampleInputEmail1">Numero </label>
          <input
            type="text"
            class="form-control"
            name="number"
            placeholder="Ingresar Nombre de la carrera"
            ref={register({
              required: "Nombre Requerido",
            })}
          />
          <p className="text-danger">
            {errors.facultad && errors.facultad.message}
          </p>
          <label for="exampleInputEmail1">Curso*</label>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Please select"
            onChange={handleChange}
          >
            {courses.map((option) => (
              <Option key={option._id}>{option.name}</Option>
            ))}
          </Select>
          {/* <p className="text-danger">
            {errors.shortName && errors.shortName.message}
          </p> */}
          <p>(*) Campos obligatorios</p>
          <Button
            className={classes.submitButton}
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
          >
            Añadir
          </Button>
        </form>
      </>
    </Modal>
  );
};

export default AddTopic;
