/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import logo from "../../../../assets/img/logo.png";
import { makeStyles } from "@material-ui/styles";

import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  colors,
} from "@material-ui/core";

import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";

import {  NotificationsPopover } from "../../../../components";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: "inherit",
  },
  searchInput: {
    flexGrow: 1,
    color: "inherit",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}));

const TopBar = (props) => {
  const { logOut } = useContext(AuthContext);
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  // const { history } = useRouter();
  const searchRef = useRef(null);
  // const dispatch = useDispatch();
  const notificationsRef = useRef(null);


  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);


  const handleLogout = () => {
    logOut();
    props.history.push("/");
  };

 
  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={logo} />
        </RouterLink>
        <div className={classes.flexGrow} />
       
        <Hidden xsDown>
          <Typography variant="h5" style={{ color: "white" }} component="h2">
            {/* {sedeactual.description()} - CONTABILIDAD {anio.get()}  | */}
          </Typography>{" "}
        </Hidden>

        <Hidden mdDown>
          {/* <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notifications.length}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon className={classes.logoutIcon} />
            Cerrar Sesión
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
 
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
