import { Button } from "antd";
import React from "react";

const Contactanos = () => {
  return (
    <div id="section4" className="container pt-5 pb-5">
      <div className="row">
        <div className="col-lg-12">
          {" "}
          <div class="d-flex justify-content-center">
            {" "}
            <h3
              style={{
                color: "#1f2762",

                // textDecoration: "underline #75CED2",
              }}
            >
              CONTACTANOS
            </h3>{" "}
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div class="form-group">
                <label for="exampleFormControlInput1">Nombres</label>
                <input type="text" class="form-control" />
              </div>
            </div>{" "}
            <div className="col-lg-6">
              <div class="form-group">
                <label for="exampleFormControlInput1">Correo electronico</label>
                <input type="email" class="form-control" />
              </div>
            </div>
          </div>
          <div className="row">
            {" "}
            <div className="col-lg-12">
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Mensaje</label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <Button type="primary">ENVIAR</Button>
        </div>
      </div>
    </div>
  );
};

export default Contactanos;
