import React from "react";
import { Link } from "react-router-dom";
import "../../assets/404.scss";
const Page404 = props => {
 
  const content = (
    <div style={{backgroundColor:"white",height:"1000px"}} >
      <div
        className=" align-items-center justify-content-center text-center"
        style={{padding:"250px"}}
      >
        <h1>This page is lost</h1>
        <p className="notfound">404</p>
        <h4>La página que solicitó no se pudo encontrar</h4>
        <br />
        <Link to={"/"}>
          {" "}
          <button type="button" class="btn btn-primary">
            Regresar al inicio
          </button>
        </Link>
      </div>
    </div>
  );
  return content;
};

export default Page404;
