import React, { useState } from "react";

import { notification } from "antd";
import useForm from "react-hook-form";
import ilustradozApi from "../../api/ilustradozApi";
import CKEditorAws from "../../components/CKEditorAws";

const Blog = () => {
  const { register, handleSubmit } = useForm({});
  const [file, setfile] = useState(null);
  const [data, setdata] = useState("");

  async function sendData(event, e) {
    let formData = new FormData();
    formData.append("image", file);
    formData.append("title", event.title);
    formData.append(
      "brief",
      data.replace(/<[^>]*>?/g, "").substr(0, 160) + "..."
    );
    formData.append("content", data);
    formData.append("status", true);

    let res = await ilustradozApi.post(`/blog/post`, formData);

    if (res.status === 200) {
      notification["success"]({
        message: "Crear Post",
        description: "El post se creo con exito",
      });
      e.target.reset();
      setdata("");
    } else {
      notification["error"]({
        message: "Crear Post",
        description: "Hubo un error al crear el post",
      });
    }
  }

  const handleChangeImg = (event) => {
    const fileUploaded = event.target.files[0];
    setfile(fileUploaded);
    // setTempImage({ imagen: URL.createObjectURL(event.target.files[0]) });
  };

  return (
    <>
      <div className="container pt-5">
        <form onSubmit={handleSubmit(sendData)}>
          <h4>CREAR POST PARA BLOG</h4>
          <div class="form-group">
            <label>Title</label>
            <input
              type="text"
              class="form-control"
              name="title"
              ref={register({
                required: "Titulo Requerido",
              })}
            />
          </div>
          <CKEditorAws data={data} setData={(data) => setdata(data)} />

          <input
            type="file"
            style={{ marginTop: "20px", marginBottom: "20px" }}
            // ref={hiddenFileInput}
            onChange={handleChangeImg}
            // style={{ display: "none" }}
          />

          <br />
          <div className="row">
            <div className="col-lg-4">
              <button type="submit" className="btn btn-primary">
                {" "}
                Crear Post
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Blog;
