import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import LineChart from "./Components/LineChart";
import { Col } from "antd";
import AppCard from "../../../../components/core/AppCard";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
}));

const PerformanceOverTime = (props) => {
  const { className, labels, data, ...rest } = props;

  return (
    <AppCard {...rest}>
      <Col xs={24} md={24}>
        <LineChart data={data} labels={labels} />
      </Col>
    </AppCard>
  );
};

export default PerformanceOverTime;
