import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Page from "./components/Page";
import { makeStyles } from "@material-ui/styles";
import TableRanking from "./components/TableRanking";
import ilustradozApi from "../../../api/ilustradozApi";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const Ranking = () => {
  const classes = useStyles();

  const [loading, setloading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getRanking();
  }, []);

  const getRanking = async () => {
    let res = await ilustradozApi.get("/user/ranking");
    if (res.status === 200) {
      setData(res.data);
    }
    setloading(false);
  };

  return (
    <>
      <Page className={classes.root}>
        <Header title="RANKING" subtitle={`Ver Ranking de Alumnos`}></Header>

        <div>
          <TableRanking loading={loading} data={data} />
        </div>
      </Page>
    </>
  );
};

export default Ranking;
