import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Routes from "./routes";
import { ThemeProvider } from "@material-ui/styles";
import "react-circular-progressbar/dist/styles.css";
import theme from "./theme";
import "./assets/custom.scss";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";

import "bootstrap/dist/js/bootstrap.js";
import "./shared/styles/vendors/index.css";
import "./shared/styles/crema.less";

import { AuthProvider } from "./context/AuthContext";

const AppState = ({ children }) => {
  return <AuthProvider>{children}</AuthProvider>;
};

const queryClient = new QueryClient({});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <AppState>
        <Router>
          <Routes />
        </Router>
      </AppState>
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
