import React from "react";
import { Radio, Input } from "antd";

import Typography from "@material-ui/core/Typography";
const Question = ({ index, data, updateQuestion }) => {
  const [value, setvalue] = React.useState(0);
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };
  const onChange = (e) => {
    setvalue(e.target.value);

    updateQuestion(index, data.multipleChoice[e.target.value].id);
  };
  return (
    <>
      <div>
        <Typography
          variant="h5"
          component="h2"
          dangerouslySetInnerHTML={{ __html: data.contentQuestion }}
        />
      </div>
      <div style={{ marginBottom: "15px" }}>
        <Radio.Group onChange={onChange} value={value}>
          {data.multipleChoice.map((e, index) => {
            return (
              <Radio key={index} style={radioStyle} value={index}>
                {e.option}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    </>
  );
};

export default Question;
