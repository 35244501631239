import React from "react";
import misionImage from "../../../assets/img/mision.png";
const Mision = () => {
  return (
    <div style={{ backgroundColor: "#f0f2f5" }} className="pt-5 " id="section2">
      <div className="container pb-5 pt-5">
        <div className="row">
          <div className="col-lg-4">
          <img
              style={{ height: "300px" }}
              src={misionImage}
            />
          </div>
          <div className="col-lg-8 pt-5">
            <h3>MISIÓN</h3>
            <p>
            Ilustrados es educación online automatizada mediante una plataforma que busca democratizar la educación,
             haciendo uso de inteligencia artificial y learning analytics y poniéndola al alcance de todos los jóvenes
              del Perú a un muy bajo costo.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mision;
