import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center"
  }
}));

const Header = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const data = {
    name: 'Shen Zhi'
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography
        component="h2"
        gutterBottom
        variant="overline"
      >
        {props.title}
      </Typography>
      <Typography
        component="h1"
        gutterBottom
        variant="h3"
      >
       {props.subtitle}
      </Typography>
     
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

Header.defaultProps = {};

export default Header;
