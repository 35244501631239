import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Layout from "../../../../core/LayoutCore";
import ilustradozApi from "../../../../api/ilustradozApi";
import { Helmet } from "react-helmet";

const columns = [
  {
    title: "Carrera",
    dataIndex: "career",
    key: "career",
    width: "40%",
  },
  {
    title: "Máximo",
    dataIndex: "maxPoints",
    align: "center",
  },
  {
    title: "Mínimo",
    dataIndex: "minPoints",
    align: "center",
  },
  {
    title: "Vacantes Ocupadas",
    dataIndex: "nQuotas",
    align: "center",
  },
];

const Results = () => {
  const [data, setData] = useState([]);

  const getMetrics = async () => {
    const res = await ilustradozApi.get("/metrics?year=2022&year=2022&type=1");
    if (res.status === 200) {
      setData(res.data);
    }
  };

  useEffect(() => {
    getMetrics();
  }, []);

  return (
    <Layout>
      <Helmet>
        <title> MÁXIMOS Y MÍNIMOS DEL EXAMEN DE ADMISIÓN 2022-I UNMSM</title>
        <meta
          name="description"
          content="NOTAS MAXIMAS Y MINIMAS SAN MARCOS 2022-1 VER POR CARRERA NOTAS MAXIMAS Y MINIMAS ADMISIÓN UNMSM"
        />
      </Helmet>
      <div className="container mt-5" id="section2">
        <p
          className="text-center"
          style={{ color: "#33333", fontSize: "36px" }}
        >
          MÁXIMOS Y MÍNIMOS DEL EXAMEN DE ADMISIÓN 2022-I UNMSM
        </p>

        <Table pagination={false} columns={columns} dataSource={data} />
      </div>
    </Layout>
  );
};

export default Results;
