import React, { useState, useEffect } from "react";
import Layout from "../../core/LayoutCore";
import Post from "./components/Post";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import ilustradozApi from "../../api/ilustradozApi";

const Blog = () => {
  const [posts, setposts] = useState([]);
  const [loading, setloading] = useState(true);
  const [filterTable, setfilterTable] = useState(null);
  const getPosts = async () => {
    let { data } = await ilustradozApi.get("/blog/posts");
    setposts(data.reverse());
    setloading(false);
  };
  useEffect(() => {
    getPosts();
  }, []);

  const search = (value) => {
    const filter = posts.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );

    setfilterTable(filter);
  };

  return (
    <Layout>
      <div className="container mt-5">
        <h2>Temas para repasar</h2>

        <div class="d-flex">
          <div class="mr-auto p-2">
            <div class="form-group">
              <select class="form-control">
                <option>Nuevos</option>

                <option>Más votados</option>
              </select>
            </div>
          </div>

          <div class="p-2">
            <label>Buscar:</label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              onChange={(e) => search(e.target.value)}
            />
          </div>
        </div>
        {loading ? (
          <div className="text-center">
            <Spin tip="Cargando..."></Spin>
          </div>
        ) : (
          <div>
            {filterTable == null
              ? posts.map((e) => (
                  <Link to={{ pathname: `/blog/${e._id}` }}>
                    <Post data={e} />
                  </Link>
                ))
              : filterTable.map((e) => (
                  <Link to={{ pathname: `/blog/${e._id}` }}>
                    <Post data={e} />
                  </Link>
                ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Blog;
