import React, { useEffect, useState } from "react";


import { Link } from "react-router-dom";
import ilustradozApi from "../../../api/ilustradozApi";
import Post from "./components/Post";
const Posts = () => {
  const [loading, setloading] = useState(true);
  const [posts, setposts] = useState([]);
  const getPosts = async () => {
    let res = await ilustradozApi.get("/blog/posts");
    setposts(res.data.reverse());
    setloading(false);
  };
  useEffect(() => {
    getPosts();
  }, []);
  return (
    <div style={{ backgroundColor: "#f0f2f5" }} className="pt-5 ">
      <div className="container pb-5">
        <div className="text-center mb-5">
          <h3>REVISA NUESTRAS ÚLTIMAS PUBLICACIONES</h3>
        </div>
        <div className="row">
          {posts.slice(0, 4).map((e) => (
            <div className="col-lg-3">
              <Link to={{ pathname: `/blog/${e._id}` }}>
                <Post data={e} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Posts;
