import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-classic-with-mathtype';
import ilustradozApi from '../api/ilustradozApi';
const CKEditorAws = props => {
  const { setData, data } = props;
  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader);
    };
  }

  const custom_config = {
    extraPlugins: [MyCustomUploadAdapterPlugin],
    toolbar: {
      items: [
        'heading', '|',
        'fontfamily', 'fontsize', '|',
        'alignment', '|',
        'fontColor', 'fontBackgroundColor', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'code', 'codeBlock', '|',
        'insertTable', '|',
        'imageUpload', 'blockQuote', '|',
        'heading', 'MathType', 'ChemType',
        'undo', 'redo'
    ],
    },
    table: { contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'] }
  };

  class MyUploadAdapter {
    constructor(loader) {
      // The file loader instance to use during the upload.
      this.loader = loader;
    }

    async upload() {
      const data = new FormData();
      let result = await this.loader.file;
      console.log(result);
      data.append('file', result);
      console.log(data);
      let res = await ilustradozApi.post(`/files/upload/imgck`, data);
  
      return { default: res.data };
    }
  }

  return (
    <>
      <CKEditor
        required
        editor={ClassicEditor}
        config={custom_config}
        data={data}
        onChange={(event, editor) => {
          const data = editor.getData();
          setData(data);
        }}
      />
    </>
  );
};

export default CKEditorAws;
