import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import TeamMember from "./components/TeamMember";
const Contactanos = () => {
  let members = [
    {
      name: "Luis Fernando Apaza Gutierrez",
      shortinfo: "Desarrollador",
      largeinfo: "Estudiante de la Universidad Nacional Mayor de San Marcos",
      img: "https://classroomfiles.s3.us-east-2.amazonaws.com/imgSchool/7f8ab594-461f-4947-a8f1-1d13d4dc87dd"
    },
    {
      name: "LUPAK JHON Quispe Ramirez",
      shortinfo: "Marketing",
      largeinfo: "Estudiante de la Universidad Nacional Mayor de San Marcos",
      img: "https://classroomfiles.s3.us-east-2.amazonaws.com/imgSchool/89b38055-9604-4aad-ad74-8d59b46f809e"
    },
 
  ];
  return (
    <div id="section3" style={{ backgroundColor: "white" }}>
      <div className="container pt-5 pb-5">
        <div className="text-center">
          {" "}
          <h3> NUESTRO EQUIPO </h3>{" "}
        </div>
        <div className="row">
          {members.map((e) => (
            <TeamMember
              name={e.name}
              shortinfo={e.shortinfo}
              largeinfo={e.largeinfo}
              img={e.img}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Contactanos;
