import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Modal, Button } from "antd";
import Typography from "@material-ui/core/Typography";
import posthttps from "../../../hook/fetch/posthttps";

import { useHistory } from "react-router-dom";
import { useState } from "react";
import AppCard from "../../../components/core/AppCard";
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function CardCursos(props) {
  const [visible, setvisible] = useState(false);
  const { publicStatus } = props;
  let history = useHistory();

  const classes = useStyles();

  async function getLesson() {
    let obj = {
      course: props.course.name,
      topic: props.text,
      // college: user.getCollege(),
    };
    let res = await posthttps("lesson", obj);
    console.log(res);
    history.push({
      pathname: "/lesson",
      state: { detail: res },
    });
  }

  function goExamen(id) {
    if (id != null) {
      history.push(`/open/course/${id}`);
    }
  }
  return (
    <AppCard>
      <Modal
        title="Alerta"
        centered
        closable={false}
        visible={visible}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={() => history.push("/login")}
      >
        <p>Primero inicia sesión</p>
      </Modal>

      <h4>
        <b> {props.reto.name}</b>
      </h4>
      <Typography variant="body2" color="textSecondary" component="p">
        Dificultad: {props.reto.difficult}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        Institución: {props.reto.institution}
      </Typography>

      <Button
        disabled={props.reto._id == null}
        onClick={() => {
          goExamen(props.reto._id);
        }}
        type="primary"
      >
        Tomar examen
      </Button>
    </AppCard>
  );
}
