import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  colors,
} from "@material-ui/core";
import SuccessSnackbar from "./SuccessSnackbar";
import useForm from "react-hook-form";
import { AuthContext } from "../../../../context/AuthContext";
import ilustradozApi from "../../../../api/ilustradozApi";
import Spinner from "../../../../components/Global/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
}));

const GeneralSettings = (props) => {
  const { user } = useContext(AuthContext);
  const { profile, className, ...rest } = props;
  const { handleSubmit, register } = useForm();
  const classes = useStyles();
  const [universities, setUniversities] = useState([]);
  const [careers, setCareers] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUniversitiesandCareers();
  }, []);

  const getUniversitiesandCareers = async () => {
    let res = await ilustradozApi.get("/academic/universities");
    let response = await ilustradozApi.get("/academic/careers");
    if (res.status === 200) {
      setUniversities(res.data);
    }
    if (response.status === 200) {
      setCareers(response.data);
    }
    setLoading(false);
  };

  console.log(universities,careers)

  const onSubmit = async (event, e) => {
    let obj = {
      university: event.university,
      career: event.career,
      firstName: event.firstName,
      lastName: event.lastName,
    };
    let res = await ilustradozApi.put("/user/setup", obj);
    if (res.status === 200) {
      setOpenSnackbar(true);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Perfil" />
        <Divider />
        <CardContent>
          {universities != null && (
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <label>Nombres</label>
                <input
                  className="form-control"
                  label="Nombres"
                  defaultValue={user.firstName}
                  name="firstName"
                  ref={register({
                    required: "Required",
                  })}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <label>Apellidos</label>
                <input
                  className="form-control"
                  label="Apellidos"
                  defaultValue={user.lastName}
                  name="lastName"
                  ref={register({
                    required: "Required",
                  })}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <label>Elegir Universidad</label>
                <select
                  className="form-control"
                  name="university"
                  defaultValue={user.university}
                  ref={register({
                    required: "Required",
                  })}
                >
                  {universities.map((e) => (
                    <option key={e} value={e}>
                      {e}
                    </option>
                  ))}
                </select>
              </Grid>

              <Grid item md={6} xs={12}>
                <label>Selecciona tu Carrera</label>
                <select
                  className="form-control"
                  name="career"
                  defaultValue={user.career}
                  ref={register({
                    required: "Required",
                  })}
                >
                  {careers.map((e, index) => (
                    <option key={index} value={e.nombre}>
                      {e?.nombre}
                    </option>
                  ))}
                </select>
              </Grid>
            </Grid>
          )}
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained"
            disable={false}
          >
            Guardar Cambios
          </Button>
        </CardActions>
      </form>
      <SuccessSnackbar onClose={handleSnackbarClose} open={openSnackbar} />
    </Card>
  );
};

export default GeneralSettings;
