import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import useForm from "react-hook-form";
import clsx from "clsx";
import { Modal } from "antd";
import { makeStyles } from "@material-ui/styles";
import { notification } from "antd";
import ilustradozApi from "../../../api/ilustradozApi";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const AddRetos = (props) => {
  const classes = useStyles();
  // const [universities, setuniversities] = useState([]);
  const [subjects, setsubjects] = useState([]);
  const { register, errors, handleSubmit } = useForm({});

  useEffect(() => {
    getSubjects();
  }, []);

  const getSubjects = async () => {
    let res = await ilustradozApi.get("/academic/subjects");
    if (res.status === 200) {
      setsubjects(res.data);
    }
  };

  async function onSubmit(event, e) {
    let obj = {
      name: event.nombre,
      difficult: event.dificultad,
      course: event.curso,
      topic: event.tema,
    };
    let res = await ilustradozApi.post(`/challenge`, obj);
    if (res.status === 200) {
      notification.success({
        message: "Crear Reto",
        description: "El Reto  fue creado satisfactoriamente.",
        key: `open${Date.now()}`,
      });
      e.target.reset();
      props.setvisible(false);
      props.setupdate({ ...true });
    } else {
      notification.error({
        message: "Crear Reto",
        description: "Ocurrio un error al grabar el Reto.",
        key: `open${Date.now()}`,
      });
    }
  }

  return (
    <Modal
      visible={props.visible}
      footer={null}
      onCancel={() => props.setvisible(false)}
    >
      <>
        <h3>Añadir Reto</h3>
        <form
          autoComplete="off"
          className={clsx(classes.root)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <label for="exampleInputEmail1">Nombre * </label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              name="nombre"
              placeholder="Ingresar Nombre del Reto"
              ref={register({
                required: "Nombre Requerido",
              })}
            />
          </div>
          <p className="text-danger">
            {errors.nombre && errors.nombre.message}
          </p>
          <label for="exampleInputEmail1">Curso *</label>
          <div class="input-group mb-3">
            <select
              name="curso"
              className="form-control"
              ref={register({
                required: "Curso Requerido",
              })}
            >
              {subjects.map((e) => (
                <option value={e._id}>{`${e.name} - ${e.university}`}</option>
              ))}{" "}
            </select>
          </div>
          <p className="text-danger">{errors.curso && errors.curso.message}</p>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Tema </label>
            <input
              type="text"
              name="tema"
              class="form-control"
              id="exampleFormControlSelect1"
              placeholder="Ingresar Tema"
              ref={register({})}
            />

            <p className="text-danger">{errors.tema && errors.tema.message}</p>
          </div>
          <label for="exampleInputEmail1">Dificultad *</label>
          <div class="input-group mb-3">
            <select
              name="dificultad"
              className="form-control"
              placeholder="Subcategoría"
              ref={register({})}
            >
              <option value="Fácil">Fácil</option>
              <option value="Intermedio">Intermedio</option>
              <option value="Difícil">Difícil</option>
            </select>

            <p className="text-danger">
              {errors.dificultad && errors.dificultad.message}
            </p>
          </div>
          <p>(*) Campos obligatorios</p>
          <Button
            className={classes.submitButton}
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
          >
            Añadir
          </Button>
        </form>
      </>
    </Modal>
  );
};

export default AddRetos;
