import React from "react";
import { Table, Divider, Tag } from "antd";

const columns = [
  {
    title: "Nombre",
    dataIndex: "firstName",
    key: "firstName",
    render: (e, r, i) => <p>{`${r.firstName} ${r.lastName}`}</p>,
  },
  {
    title: "Puntaje",
    dataIndex: "ilustradozPoints",
    key: "ilustradozPoints",
    render: (e, r, i) => <p>{Math.round(r.ilustradozPoints)} </p>,
  },
  {
    title: "Carrera",
    dataIndex: "career",
    key: "career",
  },
  {
    title: "univ",
    key: "college",
    dataIndex: "university",
    render: (tag) => (
      <span>
        <Tag color={"green"} key={tag}>
          {tag}
        </Tag>
      </span>
    ),
  },
];

const TableRanking = (props) => {
  return (
    <>
      <Table
        loading={props.loading}
        columns={columns}
        dataSource={props.data}
      />
    </>
  );
};

export default TableRanking;
