import React from "react";
import { Layout, Divider, Button } from "antd";
import MenuItems from "./Menu";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import AuthenticationService from "../auth/AuthenticationService";
import userTop from "../assets/img/userTop.svg";
import { Menu, Dropdown } from "antd";
import { useHistory } from "react-router-dom";
import facebook from "../assets/img/facebook.svg";
import skype from "../assets/img/skype.svg";
import twitter from "../assets/img/twitter.svg";
import youtube from "../assets/img/youtube.svg";
import linkedin from "../assets/img/linkedin.svg";
import correoelectronico from "../assets/img/correo-electronico.svg";
import smartphone from "../assets/img/smartphone.svg";
const { Content } = Layout;
const LayoutCore = ({ children }) => {
  let history = useHistory();
  const useStyles = makeStyles((theme) => ({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    topBar: {
      position: "relative",
    },
    container: {
      display: "flex",
      flex: "1 1 auto",
      overflow: "hidden",
    },
    navBar: {
      zIndex: 3,
      width: 256,
      minWidth: 256,
      flex: "0 0 auto",
    },
    content: {
      flex: "1 1 auto",
    },
  }));

  const handleLogout = () => {
    AuthenticationService.logout().then(() => {
      history.push("/");
    });
  };

  let arrayAdmin = [
    { name: "Usuarios", pathname: "/adm" },
    { name: "Ingresos", pathname: "/adm/ingresos" },
    { name: "Egresos", pathname: "/adm/egresos" },
    { name: " Historial de citas", pathname: "/adm/historialcitas" },
    { name: "Reporte de calificación", pathname: "/adm/reportecalificacion" },
    { name: "Medicamentos", pathname: "/adm/medicamentos" },
    {
      name: "Trazabilidad Medicamentos",
      pathname: "/adm/trazabilidadmedicamentos",
    },
    { name: "Trazabilidad de Citas", pathname: "/adm/trazabilidadcitas" },
  ];

  const optionsAdmin = () =>
    arrayAdmin.map((e) => <Link to={{ pathname: e.pathname }}>{e.name}</Link>);

  const menu = (
    <Menu>
      <Menu.Item>{optionsAdmin()}</Menu.Item>
      <Menu.Item>
        <span onClick={() => handleLogout()}>Cerrar Sesión</span>
      </Menu.Item>
    </Menu>
  );

  const classes = useStyles();
  return (
    <Layout className="layout">
      <header style={{ backgroundColor: "#1f2762" }}>
        <nav class="navbar navbar-expand-lg navbar-dark ">
          <div class="container">
            <Link class="navbar-brand" to={{ pathname: "/" }}>
              <img src={Logo} class="d-inline-block align-top" alt=""></img>
            </Link>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <Link class="nav-link active" to={{ pathname: "/" }}>
                    Home
                  </Link>
                </li>
                <MenuItems />
              </ul>
              {!(AuthenticationService.isUserAuthenticated() == true) ? (
                <div class="d-flex">
                  <Link to={{ pathname: "/login" }}>
                    <Button className="mr-2" type="primary">
                      INICIAR SESIÓN
                    </Button>
                  </Link>
                  <Link to={{ pathname: "/auth/register" }}>
                    <Button>REGISTRATE</Button>
                  </Link>
                </div>
              ) : (
                <div class="d-flex">
                  <div className="pt-1">
                    <h5 style={{ color: "white" }}>Usuario</h5>
                  </div>{" "}
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <div
                      className="p-1 ml-2"
                      style={{ border: "2px solid white", borderRadius: "50%" }}
                    >
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={userTop}
                      />
                    </div>
                  </Dropdown>{" "}
                </div>
              )}
            </div>
          </div>
        </nav>
      </header>
      <Content className="" style={{ minHeight: "100vh" }}>
        <div className={classes.content}>{children}</div>
      </Content>

      {!(AuthenticationService.isUserAuthenticated() == true) && (
        <footer
          style={{ backgroundColor: "#1f2762" }}
          className="text-muted py-5"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-2  ">
                <h4 className="text-white">PÁGINAS</h4>
                <ul className="list-unstyled">
                  <li>
                    <a href="#" className="text-white">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white">
                      Nosotros
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white">
                      Equipo ILUSTRADOZ
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white">
                      Contactanos
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3">
                <h4 className="text-white">REDES SOCIALES</h4>
                <div class="d-flex">
                  <div className="pt-2 p-1">
                    <img src={facebook} />
                  </div>
                  <div className="pt-2 p-1">
                    <img src={skype} />
                  </div>
                  <div className="pt-2 p-1">
                    <img src={youtube} />
                  </div>
                  <div className="pt-2 p-1">
                    <img src={linkedin} />
                  </div>
                  <div className="pt-2 p-1">
                    <img src={twitter} />
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <h4 className="text-white">INFORMACIÓN</h4>
                <ul className="list-unstyled">
                  <li>
                    <a href="#" className="text-white">
                      <img src={correoelectronico} /> ilustradoz@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white">
                      <img src={smartphone} /> 931957495
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4  ">
                <div class="form-group">
                  <label style={{ color: "white" }}>Ingresar un curso</label>
                  <input type="email" class="form-control" />
                </div>
              </div>
            </div>
            <Divider style={{ borderTop: "2px solid white" }} />
            <div class="d-flex">
              <div class="mr-auto p-2" style={{ color: "white" }}>
                <p style={{ fontSize: "12px" }}>
                  {" "}
                  <img
                    src={Logo}
                    width="100"
                    height="30"
                    class="d-inline-block align-top mr-2"
                    alt=""
                  ></img>
                  Copyright © 2020-2021 Ilustradoz Todos los derechos
                  reservados.
                </p>
              </div>
              <div class="p-2" style={{ color: "white" }}>
                <p style={{ fontSize: "12px" }}>Términos y condiciones</p>
              </div>
              <div class="p-2" style={{ color: "white" }}>
                <p style={{ fontSize: "12px" }}> Política de privacidad</p>
              </div>
              <div class="p-2" style={{ color: "white" }}>
                <p style={{ fontSize: "12px" }}>
                  {" "}
                  Política de cookies Notificaciones de Copyright
                </p>
              </div>
            </div>
          </div>
        </footer>
      )}
    </Layout>
  );
};

export default LayoutCore;
