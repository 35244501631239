import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Card from "@material-ui/core/Card";
import Page from "../../components/Page/Page";
import Header from "./components/Header";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/styles";
import AddUniversity from "./components/AddUniversity";
import ilustradozApi from "../../api/ilustradozApi";
import { useQuery } from "@tanstack/react-query";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const School = () => {
  const classes = useStyles();
  const [visible, setvisible] = useState(false);
  const [shortName, setshortName] = useState();

  const getSchools = async () => {
    let res = await ilustradozApi.get("/academic/schools");
    if (res.status === 200) {
      console.log(res)
      return res.data
    }
    throw new Error("Failed to fetch schools");
  };

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["school"],
    queryFn: getSchools,
  });

  const columns = [
    {
      title: "Nombre Completo",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Nombre Corto",
      dataIndex: "shortName",
      key: "shortName",
    },
    {
      title: "Imagen",
      dataIndex: "shortName",
      key: "icon",
    },
    {
      title: "Acciones",
      dataIndex: "shortName",
      key: "shortName",
      render: (value, record) => (
        <></>
        // <button
        //   type="button"
        //   onClick={() => addCareer(value)}
        //   className="btn btn-primary"getSchools
        // >
        //   Agregar Carrera
        // </button>
      ),
    },
  ];

  return (
    <>
      <Page className={classes.root}>
        <Header
          update={getSchools}
          title="Panel Admin"
          buttonText="Agregar Tema"
          onActionBtn={() => setvisible(true)}
        />
        <AddUniversity
          shortName={shortName}
          update={getSchools}
          setvisible={setvisible}
          visible={visible}
        />
        <div className="mt-3">
          <Card>
            <CardContent>
              <Table bordered={true} columns={columns} dataSource={data} loading={isLoading}/>
            </CardContent>{" "}
          </Card>
        </div>
      </Page>
    </>
  );
};

export default School;
