import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Card from "@material-ui/core/Card";
import Page from "../../components/Page/Page";
import Header from "./components/Header";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/styles";
import ilustradozApi from "../../api/ilustradozApi";
import AddCourse from "./components/AddCourse";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const Admin = () => {
  const classes = useStyles();
  const [data, setdata] = useState([]);
  const [visible, setvisible] = useState(false);
  const [schools, setSchools] = useState([]);
  const [shortName, setshortName] = useState();
  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Universidad",
      dataIndex: "school",
      key: "_id",
      render: (value, record) => <p>{record.school.shortName}</p>,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (value) => (
        <div
          style={{
            background: value,
            width: 100,
            height: 50,
          }}
        />
      ),
    },
    {
      title: "Acciones",
      dataIndex: "shortName",
      key: "shortName",
      render: (value, record) => (
        <></>
        // <button
        //   type="button"
        //   onClick={() => addCareer(value)}
        //   className="btn btn-primary"
        // >
        //   Agregar Carrera
        // </button>
      ),
    },
  ];

  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = async () => {
    let res = await ilustradozApi.get("/academic/courses");
    const responseSchools = await ilustradozApi.get("/academic/schools");
    if (res.status === 200) {
      setdata(res.data);
    }
    if (responseSchools.status === 200) {
      setSchools(responseSchools.data);
    }
  };
  return (
    <>
      <Page className={classes.root}>
        <Header
          update={getCourses}
          title="Panel Admin"
          buttonText="Agregar Curso"
          onActionBtn={() => setvisible(true)}
        />
        <AddCourse
          shortName={shortName}
          listSchool={schools}
          update={getCourses}
          setvisible={setvisible}
          visible={visible}
        />
        <div className="mt-3">
          <Card>
            <CardContent>
              <Table bordered={true} columns={columns} dataSource={data} />
            </CardContent>{" "}
          </Card>
        </div>
      </Page>
    </>
  );
};

export default Admin;
