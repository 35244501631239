import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { notification } from "antd";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  Link,
} from "@material-ui/core";
import ilustradozApi from "../../../../api/ilustradozApi";

const useStyles = makeStyles((theme) => ({
  root: {},
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  policy: {
    display: "flex",
    alignItems: "center",
  },
  policyCheckbox: {
    marginLeft: "-14px",
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
}));

const RegisterForm = (props) => {
  const { className, ...rest } = props;
  let history = useHistory();
  const classes = useStyles();
  const [firstName, setfirstName] = useState();
  const [lastName, setlastName] = useState();
  const [email, setemail] = useState();
  const [password, setpassword] = useState();
  async function SignUp() {
    let obj = {
      firstName,
      lastName,
      email,
      password,
    };
    let res = await ilustradozApi.post("/user/create", obj);
    if (res.status === 200) {
      notification.open({
        message: "Crear Cuenta",
        description: "La cuenta fue creada satisfactoriamente.",
        key: `open${Date.now()}`,
      });

      history.push("/login");
    } else {
      notification.error({
        message: "Crear Cuenta",
        description:
          "Ocurrio un error al grabar la cuenta.\n Probablemente esta cuenta ya esté creada",
        key: `open${Date.now()}`,
      });
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    SignUp();
  };

  return (
    <form
      {...rest}
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <div className={classes.fields}>
        <TextField
          label="Nombres"
          name="firstName"
          onChange={(e) => setfirstName(e.target.value)}
          variant="outlined"
        />
        <TextField
          label="Apellidos"
          name="lastName"
          onChange={(e) => setlastName(e.target.value)}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Correo "
          name="email"
          onChange={(e) => setemail(e.target.value)}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Contraseña"
          name="password"
          onChange={(e) => setpassword(e.target.value)}
          type="password"
          variant="outlined"
        />
        {/* <div>
          <div className={classes.policy}>
            <Checkbox
              checked={formState.values.policy || false}
              className={classes.policyCheckbox}
              color="primary"
              name="policy"
              onChange={handleChange}
            />
            <Typography color="textSecondary" variant="body1">
              I have read the{" "}
              <Link
                color="secondary"
                component={RouterLink}
                to="#"
                underline="always"
                variant="h6"
              >
                Terms and Conditions
              </Link>
            </Typography>
          </div>
        </div> */}
      </div>
      <Button
        className={classes.submitButton}
        color="secondary"
        size="large"
        type="submit"
        variant="contained"
      >
        Crear Cuenta
      </Button>
    </form>
  );
};

RegisterForm.propTypes = {
  className: PropTypes.string,
};

export default RegisterForm;
