import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import { Select, Button } from "antd";
import Background from "../../../assets/img/landingBackground.jpg";
import ilustradozApi from "../../../api/ilustradozApi";

const Home = () => {
  const { Option } = Select;
  const [courses, setCourses] = useState([]);

  const getCourses = async () => {
    let res = await ilustradozApi.get("/academic/subjects");
    setCourses(res.data);
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "70vh",
      }}
    >
      <div className="container pt-5">
        <div className="row " id="section1">
          <div className="col-lg-6">
            <Card>
              <CardContent>
                <div className="row">
                  <div className="col-lg-8">
                    <h4 style={{ color: "#1f2762" }}>
                      ENCUENTRA EL CURSO QUE NECESITAS
                    </h4>
                  </div>
                  <div className="col-lg-4">
                    <Link to={{ pathname: "/cursos/nuevos" }}>
                      <p style={{ color: "#6688ff" }}>Ver todos</p>
                    </Link>
                  </div>
                </div>

                <div class="form-group">
                  <label for="exampleFormControlInput1">
                    Ingresar un curso
                  </label>
                  <div class="input-group mb-3">
                    <Select
                      showSearch
                      style={{ width: "60%" }}
                      placeholder="Selecciona un curso"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {courses.map((e) => (
                        <Option value={e._id}>{e.name}</Option>
                      ))}
                    </Select>
                    <div class="input-group-append">
                      <Link to={{ pathname: "/cursos/nuevos" }}>
                        <Button type="primary">Buscar</Button>
                      </Link>
                    </div>
                  </div>
                  <label for="exampleFormControlInput1">
                    Regístrate y practica exámenes de admisión totalmente
                    gratis.
                  </label>
                  <div class="input-group-append">
                    <Link to={{ pathname: "/auth/register" }}>
                      <Button class="btn btn-secondary" type="primary">
                        Regístrate
                      </Button>
                    </Link>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
