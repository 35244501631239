import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ilustradozApi from "../../../api/ilustradozApi";

const ModalResults = ({ visible, setVisible, id, anonymousCode }) => {
  const [Result, setResult] = useState(null);

  const getResult = async () => {
    let res = await ilustradozApi.post(
      `/challenge/result-anonymous-exam/${id}`,
      { anonymous_code: anonymousCode }
    );
    if (res.status === 200) {
      setResult(res.data);
    }
  };

  useEffect(() => {
    getResult();
  }, []);

  console.log(Result);

  return (
    <Modal title="RESULTADOS" visible={visible} footer={[]} closable={false}>
      <div className="text-center">
        <div className="row">
          <div className="col-lg-12">
            {/* <h4>Nota: {Math.round(Result.points)} Puntos</h4> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalResults;
