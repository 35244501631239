
import { API_BASE_URL } from "../../config/api";
import UserServices from "../../services/UserServices";


async function deletehttps(url) {
  let response = await fetch(`${API_BASE_URL}/${url}`, {
    method: "DELETE",
    headers: UserServices.createHeaders()
  });
  let data = await response.json();

  return data;
 
}

export default deletehttps;
