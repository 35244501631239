import React from 'react'

const RetarAmigo = () => {
  return (
    <>
      
    </>
  )
}

export default RetarAmigo
