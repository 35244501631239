import React, { useEffect, useState } from "react";
import { Select } from "antd";
import gethttps from "../../hook/fetch/gethttps";
import posthttps from "../../hook/fetch/posthttps";
import puthttps from "../../hook/fetch/puthttps";
import useForm from "react-hook-form";
const { Option } = Select;
const Answers = () => {
  const { handleSubmit, register } = useForm();
  const [data, setdata] = useState([]);
  const [answer, setanswer] = useState();
  const [value, setvalue] = useState();
  const [status, setstatus] = useState(false);

  const onSendRpta = async (value, e) => {
    let res = await posthttps("/chuletas", value);
    e.target.reset();
  };

  const getChuleta = async () => {
    let res = await gethttps("/chuletas");
    setdata([...res]);
  };

  useEffect(() => {
    getChuleta();
  }, []);

  const answerResult = (value) => {
    setvalue(value);
    let found = data.find((e) => e._id == value);
    setanswer(found.answer);
    setstatus(found.status);
  };

  const renderOptions = () => {
    return data.map((i) => filterData(i));
  };
  const filterOptions = (inputValue, option) => {
    const title = option.props.title;
    return title.toLowerCase().includes(inputValue.toLowerCase(), 0);
  };
  const filterData = (i) => {
    return (
      <Option key={i._id} value={i._id} title={i.question}>
        {i.question}
      </Option>
    );
  };

  const updateQuestion = () => {
    let res = puthttps(`/chuleta/${value}`, { status: true });
    setstatus(true);
    getChuleta();
  };

  return (
    <>
      <div style={{ margin: "20px" }}>
        <h1>Ingresa la pregunta</h1>

        <Select
          showSearch
          value={value}
          style={{ width: 500 }}
          onChange={answerResult}
          placeholder="Select a question"
          optionFilterProp="children"
          style={{ width: "100%" }}
          filterOption={filterOptions}
        >
          {renderOptions()}
        </Select>

        <div>
          <h1>Resultados</h1>

          <input
            className="form-control"
            disabled={true}
            type="text"
            value={answer}
          />
          <p>Estado: {status ? "Aprobado" : "Incorrecto"}</p>
        </div>

        <button className="btn btn-success" onClick={updateQuestion}>
          APROBAR
        </button>

        <form autoComplete="off" onSubmit={handleSubmit(onSendRpta)}>
          <h1>Ingresar Pregunta</h1>

          <h3>Pregunta:</h3>
          <input
            name="question"
            className="form-control"
            ref={register({
              required: "Required",
            })}
            placeholder="Ingresar pregunta"
          />
          <h3>Respuesta:</h3>
          <input
            name="answer"
            className="form-control"
            ref={register({
              required: "Required",
            })}
            placeholder="Ingresar respuesta"
          />

          <button className="btn btn-primary" type="submit">
            ENVIAR
          </button>
        </form>
      </div>
    </>
  );
};

export default Answers;
