/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import clsx from "clsx";
import PropTypes from "prop-types";
// import { useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/styles";
import { Button, TextField } from "@material-ui/core";
import { notification, Icon } from "antd";
import { Redirect } from "react-router-dom";
import AuthenticationService from "../../../../auth/AuthenticationService";
import { useContext } from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
}));

const LoginForm = (props) => {
  const { signIn, errorMessage, removeError, status } = useContext(AuthContext);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const { className, ...rest } = props;

  const classes = useStyles();

  const [user, setuser] = useState("");
  const [password, setpassword] = useState("");

  const handleSubmit = () => {
    let obj = {
      email: user,
      password: password,
    };
    signIn(obj);
    // AuthenticationService.login(obj)
    //   .then(() => {
    //     props.onLogin();
    //     setRedirectToReferrer(true);
    //     console.log("Autenticacion exitosa !!!");
    //   })
    //   .catch(() => {
    //     notification.open({
    //       message: "Login",
    //       description: "El usuario y/o la contraseña es incorrecta.",
    //       icon: <Icon type="close" style={{ color: "#FE2E27" }} />,
    //       key: `open${Date.now()}`,
    //     });
    //   });
  };

  const { from } = props.location.state || { from: { pathname: "/" } };

  if (status === "authenticated") {
    console.log(from);
    return <Redirect to={from.pathname} />;
  }

  return (
    <form {...rest} className={clsx(classes.root, className)}>
      <div className={classes.fields}>
        <TextField
          fullWidth
          label="Correo"
          name="email"
          onChange={(e) => setuser(e.target.value)}
          value={user}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Contraseña"
          name="password"
          onChange={(e) => setpassword(e.target.value)}
          type="password"
          value={password}
          variant="outlined"
        />
      </div>
      <Button
        className={classes.submitButton}
        color="secondary"
        // disabled={!formState.isValid}
        size="large"
        type="button"
        onClick={handleSubmit}
        variant="contained"
      >
        Ingresar
      </Button>
    </form>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string,
};

export default LoginForm;
