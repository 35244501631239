import React, { useContext, useEffect, useState } from "react";
import Page from "../../components/Page/Page";
import Header from "./components/Header";
import { Alert } from "antd";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import ilustradozApi from "../../api/ilustradozApi";
import { AuthContext } from "../../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    marginTop: theme.spacing(1),
  },
}));
const Results = (props) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [Result, setResult] = useState(null);

  const getResult = async () => {
    let res = await ilustradozApi.get(`/challenge/result-exam/${id}`);
    if (res.status === 200) {
      console.log(res.data, user);
      setResult(res.data);
    }
  };

  useEffect(() => {
    getResult();
  }, []);

  return (
    <>
      <Page className={classes.root}>
        <Header title="RESULTADOS DEL EXAMEN" subtitle="DETALLES" />

        {Result != null && (
          <div className="row">
            <div className="col-lg-12">
              <h4>Nombres: {user.firstName + " " + user.lastName}</h4>
            </div>
            <div className="col-lg-12">
              <h4>Nota: {Math.round(Result.points)} Puntos</h4>
            </div>
            {Result.exam.list_answers.map((e, i) => {
              console.log(e);
              const choice = e.multipleChoice.find((m) => m.id === e.choice);
              console.log(choice);
              return (
                <>
                  <div className="col-lg-12">
                    <div
                      dangerouslySetInnerHTML={{ __html: e.contentQuestion }}
                    ></div>
                  </div>

                  <div className="col-lg-12">
                    <p>Tu Respuesta:</p>
                    <Alert
                      message={choice.option}
                      type={
                        e.uniqueSolution === choice.id ? "success" : "error"
                      }
                    />
                  </div>
                </>
              );
            })}
          </div>
        )}
      </Page>
    </>
  );
};

export default Results;
