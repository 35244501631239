import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext.jsx";

import Home from "../../Global/Homes.jsx";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { status } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (status === "authenticated") {
          return (
            <Home {...props}>
              <Component {...props} />
            </Home>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    ></Route>
  );
};
