import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Link,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LayoutCore from "../../core/LayoutCore";
import gradients from "../../utils/gradients";
import LoginForm from "./components/LoginForm/LoginForm";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6, 2),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "unset",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  icon: {
    backgroundImage: gradients.blue,
    color: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Login = (props) => {
  console.log(props);
  const classes = useStyles();

  return (
    <LayoutCore>
      <div className="pl-5 pt-5">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <LockIcon className={classes.icon} />
            <Typography gutterBottom variant="h3">
              INGRESAR
            </Typography>
            <Typography variant="subtitle2">Ingresar a Ilustradoz</Typography>
            <LoginForm
              location={props.location}
              onLogin={props.onLogin}
              className={classes.loginForm}
            />

            <Divider className={classes.divider} />
            <Link
              align="center"
              color="secondary"
              component={RouterLink}
              to="/auth/register"
              underline="always"
              variant="subtitle2"
            >
              ¿No tienes una cuenta?
            </Link>
            <a href="https://www.facebook.com/Ilustradoz-949226588523447/">
              <Divider className={classes.divider} />
              <small>Ilustradoz © 2020</small>
            </a>
          </CardContent>
        </Card>
      </div>
    </LayoutCore>
  );
};

export default Login;
