import { API_BASE_URL } from "../../config/api";
import UserServices from "../../services/UserServices";

async function posthttps(url) {
  let response = await fetch(`${API_BASE_URL}/${url}`, {
    method: "GET",
    headers: UserServices.createHeaders()
  });
 
  let data;
  if (response.status == 200) {
    data = await response.json();
   
    return data;
  } else {
    data = [];
    return data;
  }
}

export default posthttps;
