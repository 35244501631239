import React, { useEffect, useState } from "react";
import { Select } from "antd";

import Page from "../Login/Cursos/components/Page";
import { Divider } from "antd";
import { makeStyles } from "@material-ui/styles";
import Header from "../../components/Global/Header";
import Block from "./components/Block";
import ilustradozApi from "../../api/ilustradozApi";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const { Option } = Select;
const Learn = () => {
  const classes = useStyles();
  const [subjects, setSubjects] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [subject, setSubject] = useState(null);
  function onChange(value) {
    console.log(`selected ${value}`);
    setSubject(value);
  }

  const onSearch = (val) => {
    console.log("search:", val);
  };

  useEffect(() => {
    getSubjects();
  }, []);

  useEffect(() => {
    getBlocks(subject);
  }, [subject]);

  const getSubjects = async () => {
    let res = await ilustradozApi.get("/academic/subjects");
    if (res.status === 200) {
      setSubjects(res.data);
    }
  };

  const getBlocks = async (subject) => {
    let res = await ilustradozApi.post("/lessons/lessons", { subject });
    if (res.status === 200) {
      setBlocks(res.data);
    }
  };

  console.log(blocks);

  return (
    <>
      <Page className={classes.root}>
        <Header title="APRENDER" subtitle={`APRENDER`} />
        <div>
          <div class="form-group">
            <p>Selecciona un curso y desarrolla lecciones</p>
            <br />
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Ingresa el curso"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {subjects.map((subject) => (
                <Option value={subject._id}>
                  {subject.name.toString().toUpperCase()}
                </Option>
              ))}
            </Select>
          </div>
          {blocks.map((block) => (
            <>
              <Block data={block} />
              <Divider />
            </>
          ))}
        </div>
      </Page>
    </>
  );
};

export default Learn;
