import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import useForm from "react-hook-form";

import clsx from "clsx";
import { Modal } from "antd";
import { makeStyles } from "@material-ui/styles";
import { notification } from "antd";
import ilustradozApi from "../../../api/ilustradozApi";
import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));
const AddRetos = (props) => {
  const classes = useStyles();
  const { update } = props;
  const { register, errors, handleSubmit } = useForm({});
  const [options, setoptions] = useState([]);

  async function onSubmit(event, e) {
    let res = await ilustradozApi.post("/academic/school", event);
    if (res.status === 200) {
      notification.success({
        message: "Crear un Universidad",
        description: "El tema fue creado satisfactoriamente.",

        key: `open${Date.now()}`,
      });
      e.target.reset();
      props.setvisible(false);
      update();
    } else {
      notification.error({
        message: "Error Universidad",
        description: "Ocurrio un error al grabar la universidad",
        key: `open${Date.now()}`,
      });
    }
  }

  return (
    <Modal
      visible={props.visible}
      footer={null}
      onCancel={() => props.setvisible(false)}
    >
      <>
        <h3>Añadir Universidad</h3>
        <form
          className={clsx(classes.root)}
          autocomplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          {" "}
          <label>Nombre completo* </label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              name="name"
              placeholder="Ingresar nombre del curso"
              ref={register({
                required: "Nombre Requerido",
              })}
            />
          </div>
          <p className="text-danger">
            {errors.nombre && errors.nombre.message}
          </p>
          <label>Nombre corto* </label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              name="shortName"
              placeholder="Ingresar nombre del curso"
              ref={register({
                required: "Nombre Requerido",
              })}
            />
          </div>
          <p className="text-danger">
            {errors.nombre && errors.nombre.message}
          </p>
          <p>(*) Campos obligatorios</p>
          <Button
            className={classes.submitButton}
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
          >
            Añadir
          </Button>
        </form>
      </>
    </Modal>
  );
};

export default AddRetos;
