class anio {
  static validaranio() {
    let año = JSON.parse(sessionStorage.getItem("anio-actual"));
    if (año !== null) {
      if (Number.isInteger(Number(año.value))) {
        return false;
      } else return true;
    } else {
      return true;
    }
  }

  static get() {
    let año = JSON.parse(sessionStorage.getItem("anio-actual"));
    var fecha = new Date();
    var anio = fecha.getFullYear();
    if (año == null) {
      return anio;
    }
    return año.value;
  }

  static getActual() {
    let date = new Date();
    var anio = date.getFullYear();
    return anio;
  }
}

export default anio;
