import React from "react";
import { Select } from "antd";
const { Option } = Select;

const SelectQuestions = (props) => {
  const { options } = props;

  const handleChange = (value)=> {
    console.log(`selected ${value}`);
  }
  return (
    <>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Please select"
        defaultValue={["a10", "c12"]}
        onChange={handleChange}
      >
        {options.map((option) => (
          <Option key={option}>{option}</Option>
        ))}
      </Select>
    </>
  );
};

export default SelectQuestions;
