import React from "react";
import { useHistory } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
const Menu = () => {
  let history = useHistory();
  return (
    <>
      <li class="nav-item">
        <Link
          class="nav-link active"
          activeClass=" nav-link active"
          to="section2"
          spy={false}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => history.push("/")}
        >
          Nosotros
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class="nav-link active"
          activeClass="nav-link active"
          to="section3"
          spy={false}
          onClick={() => history.push("/")}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Equipo
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class="nav-link active"
          activeClass="nav-link active"
          onClick={() => history.push("/cursos/nuevos")}
        >
          Retos ILUSTRADOZ
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class="nav-link active"
          activeClass="nav-link active"
          onClick={() => history.push("/blog")}
        >
          Blog
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class="nav-link active"
          activeClass="nav-link active"
          onClick={() => history.push("/results")}
        >
          Resultados
        </Link>
      </li>
      <li class="nav-item">
        <Link
          class="nav-link active"
          activeClass=" nav-link active"
          to="section4"
          spy={false}
          smooth={true}
          onClick={() => history.push("/")}
          offset={-70}
          duration={500}
        >
          Contactanos
        </Link>{" "}
      </li>
    </>
  );
};

export default Menu;
