import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
const useAnonymous = () => {
  const [anonymousCode, setAnonymousCode] = useState(null);

  const verifyAnonymous = async () => {
    let user = await JSON.parse(localStorage.getItem("ilustradoz-user"));
    if (!user) {
      let anonymous = await JSON.parse(
        localStorage.getItem("ilustradoz-anonymous")
      );

      if (!anonymous) {
        const dataObject = {
          anonymous_code: uuidv4(),
        };

        await localStorage.setItem(
          "ilustradoz-anonymous",
          JSON.stringify(dataObject)
        );
        setAnonymousCode(dataObject.anonymous_code);
      } else {
        setAnonymousCode(anonymous.anonymous_code);
      }
    }
  };
  useEffect(() => {
    verifyAnonymous();
  }, []);
  return { anonymousCode };
};

export default useAnonymous;
