import React from "react";
import { Card } from "@material-ui/core";
import moment from "moment";
const Post = (props) => {
  const { data } = props;
  return (
    <Card className="mb-3">
      <div className="row">
        <div className="col-lg-6">
          <div className="p-3">
            <h4>
              <b>{data.title.toUpperCase()}</b>{" "}
            </h4>
            <p style={{ color: "#333" }}>
              <div dangerouslySetInnerHTML={{ __html: data.brief }}></div>
            </p>
            <div
              style={{ position: "absolute", bottom: "10px" }}
              class="d-flex flex-row"
            >
              <div style={{ color: "#707070" }} class="p-2">
                Autor: {data?.user}
              </div>
              <div style={{ color: "#707070" }} class="p-2">
                {moment(data.updatedAt).format("DD-MM-YYYY")}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <img
            style={{
              width: "100%",
              height: "300px",
              objectFit: "cover",
            }}
            src={
              data.image
                ? data.image
                : "https://classroomfiles.s3.us-east-2.amazonaws.com/imgSchool/089e309b-54eb-42b3-9864-63213c8765e1"
            }
          />
        </div>
      </div>
    </Card>
  );
};

export default Post;
