import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import ViewModuleIcon from "@material-ui/icons/ViewModule";

export default [
  {
    title: "",
    pages: [
      {
        title: "Inicio",
        href: "/",
        icon: HomeIcon,
      },
      {
        title: "Aprender",
        href: "/aprender",
        icon: DashboardIcon,
        children: [
          // {
          //   title: "Aprender",
          //   href: "/learn",
          // },
          {
            title: "Retos",
            href: "/reto",
          },
        ],
      },

      {
        title: "Ranking",
        href: "/ranking",
        icon: PeopleIcon,
      },
    ],
  },
  {
    title: "CONFIGURACIÓN",
    pages: [
      {
        title: "Perfil",
        href: "/settings/general",
        icon: ViewModuleIcon,
      },
    ],
  },
];
