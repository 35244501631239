//Dependencies
import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";

//Components

import Page404 from "./components/Global/Page404";
import Login from "./views/Login/Login";

import Inicio from "./components/Global/inicio";

import { ProtectedRoute } from "./components/Util/Ruta/ProtectedRoute";

import Cursos from "./views/Login/Cursos/Cursos";
import Ranking from "./views/Login/Ranking/Ranking";
import Profile from "./views/Login/Profile/Profile";
import Lesson from "./views/Lesson/Lesson";
import Areas from "./views/Areas/Areas";
import Exam from "./views/Exam/Exam";
import Results from "./views/Results/Results";
import ResultsUNMSM from "./views/Landing/components/Results/Results";
import Register from "./views/Register/Register";
import RetarAmigo from "./views/Retos/RetarAmigo/RetarAmigo";
import Retos from "./views/Retos/Reto/Retos";
import Questions from "./views/Retos/Questions/Questions";
import Courses from "./views/Admin/Courses";
import Landing from "./views/Landing/Landing";
import CursosPublicos from "./views/CursosPublicos/CursosPublicos";
import Blog from "./views/Blog/Blog";
import BlogAdmin from "./views/Admin/Blog";
import BlogPost from "./views/Blog/BlogPost";
import Answers from "./views/Answers/Answers";
import { AuthContext } from "./context/AuthContext";
import Learn from "./views/Learn";
import Blocks from "./views/Blocks/Blocks";
import ExamAnonymous from "./views/Exam/ExamAnonymous";
import Ruray from "./views/Ruray";
import School from "./views/Admin/School";
import Topics from "./views/Admin/Topics";
import CreateQuestions from "./views/Retos/Questions/CreateQuestions";
import Tyc from "./views/tyc";

const Routes = () => {
  const { status } = useContext(AuthContext);

  return (
    <Switch>
      <Route exact path="/auth/register" component={Register} />
      <Route exact path="/blog" component={Blog} />
      <Route exact path="/blog/:id" component={BlogPost} />
      <Route exact path="/chuletas" component={Answers} />
      <Route exact path="/login" render={(props) => <Login {...props} />} />
      {status === "authenticated" ? (
        <ProtectedRoute exact path="/" component={Inicio} />
      ) : (
        <Route exact path="/login" render={(props) => <Login {...props} />} />
      )}
      <Route exact path="/" component={Landing} />
      <Route exact path="/ruray" component={Ruray} />
      <Route exact path="/tycilustradoz" component={Tyc} />
      <Route exact path="/cursos/nuevos" component={CursosPublicos} />
      <Route
        exact
        path="/open/course/:challenge_id"
        component={ExamAnonymous}
      />
      <ProtectedRoute exact path="/cursos" component={Cursos} />
      <ProtectedRoute exact path="/ranking" component={Ranking} />
      <ProtectedRoute exact path="/lesson" component={Lesson} />
      <ProtectedRoute exact path="/areas" component={Areas} />

      <ProtectedRoute exact path="/areas/exam/:challenge_id" component={Exam} />
      <ProtectedRoute
        exact
        path="/areas/exam/:id/results"
        component={Results}
      />
      <Route exact path="/results" component={ResultsUNMSM} />

      <ProtectedRoute exact path="/settings/:tab" component={Profile} />
      <ProtectedRoute
        exact
        path="/settings/admin/courses"
        component={Courses}
      />
      <ProtectedRoute exact path="/settings/admin/blog" component={BlogAdmin} />
      <ProtectedRoute exact path="/settings/admin/topics" component={Topics} />
      <ProtectedRoute exact path="/settings/admin/school" component={School} />
      <ProtectedRoute exact path="/reto/friend" component={RetarAmigo} />
      <ProtectedRoute exact path="/create/question" component={RetarAmigo} />
      <ProtectedRoute exact path="/reto" component={Cursos} />
      <ProtectedRoute exact path="/learn" component={Learn} />
      <ProtectedRoute exact path="/questions" component={CreateQuestions} />

      <ProtectedRoute exact path="/mychallenges" component={Retos} />
      <ProtectedRoute exact path="/myblocks" component={Blocks} />
      <ProtectedRoute
        exact
        path="/mychallenges/:id_challenge/addquestions"
        component={Questions}
      />

      <ProtectedRoute path="/home" component={Inicio} />
      <Route path="" component={Page404} />
    </Switch>
  );
};

export default Routes;
