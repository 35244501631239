import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Page from "../../../components/Page/Page";
import Header from "./components/Header";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import TableQuestions from "./components/TableQuestions";
import useForm from "react-hook-form";
import ilustradozApi from "../../../api/ilustradozApi";
import CKEditorAws from "../../../components/CKEditorAws";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paginate: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));

const Questions = (props) => {
  const { id_challenge } = useParams();
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm({});

  const [visible, setVisible] = useState(false);
  const [challenge, setChallenge] = useState();
  const [update, setupdate] = useState(null);
  const [contentQuestion, setContentQuestion] = useState("");
  const [questions, setquestions] = useState([]);

  const onSubmit = async (e) => {
    console.log(e);
    let formData = new FormData();
    formData.append("answer", e.correctAnswer);
    formData.append("contentQuestion", contentQuestion);
    formData.append("alternative1", e.alternative1);
    formData.append("alternative2", e.alternative2);
    formData.append("alternative3", e.alternative3);
    formData.append("alternative4", e.alternative4);

    if (contentQuestion) {
      let res = await ilustradozApi.post(
        `/challenge/${id_challenge}/add-question`,
        formData
      );
    }

    getQuestions();
  };

  const deleteQuestion = async (id_question) => {
    let res = await ilustradozApi.delete(`/challenge/${id_question}/`);
    setVisible(false);
    getQuestions();
  };

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = async () => {
    let res = await ilustradozApi.get(
      `/challenge/questions-challenge/${id_challenge}`
    );
    if (res.status === 200) {
      setquestions(res.data);
    }
  };

  function handleCancel(e) {
    setVisible(false);
  }
  const showModalDelete = (id) => {
    setVisible(true);
    setChallenge(id);
  };

  return (
    <Page className={classes.root}>
      <Header
        title="crear contenido"
        subtitle="MIS RETOS"
        setupdate={setupdate}
      />

      {visible && (
        <Modal
          onOk={() => deleteQuestion(challenge)}
          onCancel={handleCancel}
          title="Eliminar"
          visible={visible}
        >
          <p>¿Seguro que desea eliminar la pregunta?</p>
        </Modal>
      )}
      <div className="mt-3">
        <Card>
          <CardContent>
            <h3>INGRESAR PREGUNTA</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label>Datos de la pregunta</label>
                <CKEditorAws
                  data={contentQuestion}
                  setData={setContentQuestion}
                />
              </div>
              <br />
              <div className="row">
                {" "}
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Subir imagen</label>
                    <input
                      type="file"
                      className="form-control-file"
                      id="exampleFormControlFile1"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-6">
                  <label>Alternativa 1</label>
                  <input
                    className="form-control"
                    name="alternative1"
                    type="text"
                    ref={register({
                      required: "Requerido",
                    })}
                  />
                  <p className="text-danger">
                    {errors.alternative1 && errors.alternative1.message}
                  </p>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-6">
                  <label>Alternativa 2</label>
                  <input
                    className="form-control"
                    name="alternative2"
                    type="text"
                    ref={register({
                      required: "Requerido",
                    })}
                  />
                  <p className="text-danger">
                    {errors.alternative2 && errors.alternative2.message}
                  </p>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-6">
                  <label>Alternativa 3</label>
                  <input
                    className="form-control"
                    name="alternative3"
                    type="text"
                    ref={register({
                      required: "Requerido",
                    })}
                  />
                  <p className="text-danger">
                    {errors.alternative3 && errors.alternative3.message}
                  </p>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-6">
                  <label>Alternativa 4</label>
                  <input
                    className="form-control"
                    name="alternative4"
                    type="text"
                    ref={register({
                      required: "Requerido",
                    })}
                  />
                  <p className="text-danger">
                    {errors.alternative4 && errors.alternative4.message}
                  </p>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-6">
                  <label>Respuesta correcta</label>
                  <select
                    className="form-control"
                    name="correctAnswer"
                    ref={register({
                      required: "Requerido",
                    })}
                  >
                    <option value={0}>Alternativa 1</option>
                    <option value={1}>Alternativa 2</option>
                    <option value={2}>Alternativa 3</option>
                    <option value={3}>Alternativa 4</option>
                  </select>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-12">
                  <button type="submit" className="btn btn-primary">
                    Crear Pregunta
                  </button>
                </div>
              </div>
            </form>
          </CardContent>{" "}
        </Card>
        <br />
        <CardContent>
          <TableQuestions update={showModalDelete} questions={questions} />
        </CardContent>
      </div>
    </Page>
  );
};

export default Questions;
