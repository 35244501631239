import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

const LineChart = (props) => {
  const { data, labels } = props;

  const dataDisplay = () => {
    let display = [];

    data.forEach((e, i) => {
      display.push({
        day: labels[i],
        Puntos: e,
      });
    });

    return display;
  };

  return (
    <ResponsiveContainer width="100%" height={280}>
      <BarChart
        data={dataDisplay()}
        margin={{
          top: 15,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 1"
          horizontal={true}
          vertical={false}
        />
        <XAxis dataKey="day" />
        <Tooltip labelStyle={{ color: "black" }} />
        <Bar dataKey="Puntos" fill="#F44D50" barSize={8}  />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
